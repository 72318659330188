import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './Playing.scss'
import useApi from '../../Hooks/useApi'
import correct from '../../assets/audio/correct-worksheet.mp3';
import useSound from 'use-sound';
import next from '../../assets/audio/next.mp3';
import error from '../../assets/audio/error-worksheet.mp3';
import sw from '../../assets/audio/switch.mp3';
import Loader from '../Loading';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';


export default function Quiz({setMagicBar, setMagicNumber}) {
    setMagicBar(false);
setMagicNumber(2)

    const navigate = useNavigate();
    const [isSoundOn, setIsSoundOn] = useState(true);
    const [correct1] = useSound(correct);
    const [error1] = useSound(error);
    const [next1] = useSound(next);
    const [switc] = useSound(sw);
    const playSound = (soundName) => {
        isSoundOn && soundName()
    }
    const queryParams = new URLSearchParams(window.location.search)
    const level = queryParams.get("lvlid")
    const topid = queryParams.get("topid")
    const src = queryParams.get("src")
    const { loading, data, error:error11 } = useApi(`${process.env.REACT_APP_SERVER}/api/levels/${level}`)
    useEffect(() => {
        if(error11) {
            alert("Something went wrong, please try again later")
            navigate('/quiz')
        }
    }, [error11])
    useEffect(() => {
        window.onbeforeunload = function () {
            return "Dude, are you sure you want to leave? Think of the kittens!";
        }
    }, [])
    const levels = loading ? {} : data.data
    const question = loading ? [] : levels.attributes.questions
    // const shuffleArray = array=>{
    //     for (let i=array.length-1;i>0;i--) {
    //         const j=Math.floor(Math.random() * (i+1));
    //         const temp =array[i];
    //         array[i] =array[j];
    //         array[j]=temp;
    //     }
    //     return array;
    // };
    // setQuestion(temp)
    const [queNo, setQueNo] = useState(1)
    const [isAttempted, setIsAttempted] = useState(false)
    const [isCorrect, setIsCorrect] = useState(false)
    const no_of_questions = question.length

    const [score, setScore] = useState(0)
    const [i, setI] = useState(0)
    const progress_bar = () => {
        let content = []
        for (let i = 1; i <= no_of_questions; i++) {
            content.push(<div
                className={`step rounded-pill d-inline-block text-center position-relative ${queNo === i ? "active" : ""}`}
            // onClick={() => {
            //     setQueNo(i)
            //     setDragans([])
            //     setIsAttempted(false)
            //     setIsCorrect(false)
            // }}
            >{i}
            </div>)
        }
        return content
    }
    const [dragans, setDragans] = useState([])
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (!isAttempted) {
            setDragans([question[queNo - 1].questions.split(" ")[result.source.index]])
            setIsAttempted(true)
            if (question[queNo - 1].answer === result.source.index) {
                playSound(correct1)
                setIsCorrect(true)
                setScore(score + 1)
            } else {
                playSound(error1)
            }
        }
    }

    const onclick = (x) => {
        if (!isAttempted) {
            setIsAttempted(true)
            setI(x)
            if (question[queNo - 1].answer === x) {
                playSound(correct1)
                setIsCorrect(true)
                setScore(score + 1)

            } else {
                playSound(error1)
            }
        }
    }
    // const [time, setTime] = useState(30);
    // decrement time every second
    // useEffect(() => {
    //     if (time > 0) {
    //         const timer = setTimeout(() => {
    //             setTime(time - 1);
    //         }, 1000);
    //     } else {
    //         setTime(30)
    //         setQueNo(queNo + 1)
    //         setDragans([])
    //         setIsAttempted(false)
    //         setIsCorrect(false)
    //     }
    // }, [time]);
    // const [isTimeUp, setIsTimeUp] = useState(false);
    return (
        <>

            {loading ? (<Loader/>) : (
                <div className='playing'>
                    {/* <div className="timer">
                        {time}
                    </div> */}
                    <div
                        className="vol"
                        onClick={() => {
                            !isSoundOn && switc()
                            setIsSoundOn(!isSoundOn)
                        }}
                    >
                        {
                            isSoundOn ?
                                (
                                    <i className='fa fa-volume-up'></i>
                                ) : (
                                    <i className="fa fa-volume-mute"></i>
                                )
                        }
                    </div>
                    {/* {JSON.stringify(question)} */}
                    <div className="area" >
                        <ul className="circles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <div className='round-time-bar' data-style="smooth" >
                        </div>
                        <div className="wrapper position-relative">
                            <div className="container-fluid">
                                <div className="step_bar_content ps-5 pt-5">
                                    <h5 className="text-white text-uppercase d-inline-block"> {levels.attributes.topic} {levels.attributes.difficulty} {levels.attributes.number}</h5>
                                </div>
                                <div className="progress_bar steps_bar mt-3 ps-5 d-inline-block">
                                    {progress_bar()}
                                </div>


                                <div className="form_title ps-5">
                                    <h1 className="text-white">{question[queNo - 1].title}</h1>
                                </div>


                                {question[queNo - 1].type ?
                                    (
                                        <>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <div className="form_title_2 ps-5">
                                                    <h1 className="text-white">
                                                        <Droppable droppableId='question'>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className='ul'
                                                                    ref={provided.innerRef}
                                                                    {...provided.droppableProps}
                                                                >
                                                                    {
                                                                        question[queNo - 1].questions.split(" ").map((e, index) =>
                                                                            <Draggable
                                                                                draggableId={`${e}_${index}`}
                                                                                index={index}
                                                                                key={index}
                                                                                isDragDisabled={isAttempted}
                                                                            >
                                                                                {(provided, snapshot) => (

                                                                                    <div className={`li ${snapshot.isDragging ? 'moving' : ''} ${isAttempted ? isCorrect ? '' : index === question[queNo - 1].answer ? 'ddc' : '' : ''}`} key={index}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}
                                                                                        id={index}
                                                                                    >{e}</div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    }
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </h1>
                                                </div>
                                                <div className='answerBox'>
                                                    <Droppable droppableId='question'>
                                                        {(provided, snapshot) => (

                                                            <div
                                                                className={`dropBox ${snapshot.isDraggingOver ? 'over' : ''} ${isAttempted ? isCorrect ? 'correct' : 'incorrect' : ''}`}
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                {dragans[0]}
                                                            </div>

                                                        )

                                                        }

                                                    </Droppable>
                                                </div>
                                            </DragDropContext>
                                        </>
                                    ) : (
                                        <>
                                            <div className="form_title_2 ps-5">
                                                <h1 className="text-white">{JSON.parse(JSON.stringify(question[queNo - 1].questions))}</h1>
                                            </div>
                                            <div className='answerBox'>
                                                <div className='options'>
                                                    {question[queNo - 1].options.map((e) =>
                                                        <div
                                                            className={`option ${question[queNo - 1].options.indexOf(e) === i ? isAttempted ? isCorrect ? 'correct' : 'incorrect' : '' : ''} ${isAttempted ? question[queNo - 1].answer === question[queNo - 1].options.indexOf(e) ? 'correct' : '' : ''}`}
                                                            onClick={() => onclick(question[queNo - 1].options.indexOf(e))}
                                                        >
                                                            {e}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                {/* {question[queNo - 1].type ?
                                () :
                                ()} */}

                                <div className="form_btn py-5 d-flex justify-content-center align-items-center">
                                    <button
                                        type="button"
                                        className={`f_btn rounded-pill active ${queNo === no_of_questions ? "nop" : "yeah"}`}
                                        onClick={() => {
                                            question[queNo - 1].isAttempted = isAttempted
                                            question[queNo - 1].isCorrect = isCorrect
                                            setQueNo(queNo + 1)
                                            playSound(next1)
                                            setDragans([])
                                            setIsAttempted(false)
                                            setIsCorrect(false)
                                        }
                                        }>
                                        Next Question
                                        <span>
                                            <i className="fas fa-arrow-right ps-1"></i>
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className={`f_btn rounded-pill active ${queNo === no_of_questions ? "nop" : "yeah"}`}
                                        onClick={() => {
                                            setQueNo(queNo + 1)
                                            setDragans([])
                                            setIsAttempted(false)
                                            setIsCorrect(false)
                                        }}>
                                        <span>
                                            <i className="fa fa-ban"></i>
                                        </span>
                                        Skip
                                    </button>
                                    <Link to={`/quiz/${src === "single" ? `score-single/${btoa(`?score=${score}&t=${no_of_questions * 1}&lvl=${levels.attributes.topic}&difficulty=${levels.attributes.difficulty}&num=${levels.attributes.number}&lvlid=${level}&topid=${topid}`)}` : "scores"}`}>
                                        <button
                                            type="button"
                                            className={`f_btn rounded-pill active ${queNo === no_of_questions ? "yeah" : "nop"}`}
                                        onClick={() => {
                                            if (!window.localStorage.getItem("id")) return
                                            const id = JSON.parse(window.localStorage.getItem("id"))
                                            axios.post(`${process.env.REACT_APP_SERVER}/api/user/quiz`, {
                                                score: score,
                                                levelid: level,
                                                userid: id,
                                                length: no_of_questions * 1,
                                            })
                                        }}
                                        >
                                            Submit
                                            <span>
                                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                            </span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </>
    )
}