import React from 'react'
import './loader.scss'
export default function Loader() {
    return (
        <div className="loading-area" >
            <div className="loader1" >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
