import React from 'react'
import "./VocabScore.scss"
import Confetti from 'react-confetti'
import useWindowDimensions from '../../Hooks/useWindowDimensions'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function SingleScore({setMagicBar, setMagicNumber}) {
    const { width, height } = useWindowDimensions()
    setMagicBar(true);
setMagicNumber(3)

    // const { tag } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const src = queryParams.get("src")
    const difficulty = queryParams.get("difficulty")
    const level = queryParams.get("level")
    const t1 = queryParams.get("t1")
    const t2 = queryParams.get("t2")
    const meta = queryParams.get("meta")
    //   const { loading, data } = useApi(`${process.env.REACT_APP_SERVER}/api/topics/${topic}`)
    const loading = false
    //   const top = loading ? [] : data.data
    //   const attributes = loading ? {} : top.attributes

    return (
        <>
            {!loading ? (
                <>
                    { t1 === 'You Won'
                     &&
      <Confetti
      width={width}
      height={height}
      />
    }
                    <div className="VocabScore">
                        <div className="box">
                            <div className="single-box">
                                <div className="img">
                                    {/* <i class={`fa fa-star true`} ></i> */}
                                    {t1 === 'You Won' ? (

                                        <i class={`fas fa-star true`}></i>
                                    ) : (
                                        <div style={{fontSize:'4rem'}}>🥺</div>
                                    ) }
                                    {/* <i class={`fa fa-star true`}></i> */}
                                </div>
                                <h2>{t1}</h2>
                                <p>{t2}</p>
                                {/* <p></p> */}
                                <Link to="/vocabulary/games">Home</Link>
                                <Link to={`/vocabulary/games/wordcard?src=${src}&difficulty=${difficulty}&level=${parseInt(level)}`}>Play again</Link>
                                {parseInt(level) < parseInt(meta) && (
                                    <Link to={`/vocabulary/games/wordcard?src=${src}&difficulty=${difficulty}&level=${parseInt(level) + 1}`}>Next</Link>
                                )}
                            </div>

                        </div>
                    </div>

                </>
            ) : null}
        </>
    )
}
