import React from 'react';
import { Link } from 'react-router-dom';
import qa from '../../assets/images/icon/1a.jpg';
import qb from '../../assets/images/icon/2b.jpg';
import qc from '../../assets/images/icon/3c.jpg';
import qd from '../../assets/images/icon/4d.jpg';
import './Services.scss'
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ServicesHomeOne({ className }) {
    return (
        <section className={`service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container" style={{margin:0 , maxWidth: '100%'}}>
                <div className="row justify-content-center feature-title">
                    <div className="col-lg-6">
                        <div className="section-title text-center">
                            <h3 className="title">Grammar Practice Features</h3>
                            {/* <p className='subhead'> For Kids Aged 7 to 10 years </p> */}
                        </div>
                    </div>
                </div>
                <div className="serve-container">
                    <div className="cardserv">
                        <div className="imgBx">
                            <img src={qa} />
                        </div>
                        <div className="contentBx">
                            <h2>Grammar Worksheets</h2>
                            <div className="size">
                                <h3 style={{fontSize:"16px"}}>Here, Students can practice their grammar skills by completing Engaging Grammar worksheets as well as understanding grammar concepts through Concept Sheets.</h3>
                            </div>
                            <FontAwesomeIcon icon={faHandPointRight}/>
                            <Link to="/worksheets">View Now</Link>
                        </div>
                    </div>
                    <div className="cardserv">
                        <div className="imgBx">
                            <img src={qb} />
                        </div>
                        <div className="contentBx">
                            <h2>Grammar <br /> Quizzes</h2>
                            <div className="size">
                                <h3 style={{fontSize:"16px"}}>Quizzes are the best way to practice grammar topics and in this feature, Students can also create a room and practice Grammar quizzes with their friends.</h3>
                            </div>

                            <FontAwesomeIcon icon={faHandPointRight}/>
                            <Link to="/quiz">Play Now</Link>
                        </div>
                    </div>
                    <div className="cardserv">
                        <div className="imgBx">
                            <img src={qc} />
                        </div>
                        <div className="contentBx">
                            <h2>Vocabulary With Games</h2>
                            <div className="size">
                                <h3>Students can learn new words every day and Play different types of Vocabulary Games to improve their vocabulary skills.</h3>
                            </div>

                            <FontAwesomeIcon icon={faHandPointRight}/>
                            <Link to="/vocabulary">Learn Now</Link>
                        </div>
                    </div>
                    <div className="cardserv">
                        <div className="imgBx">
                            <img src={qd} />
                        </div>
                        <div className="contentBx">
                            <h2>Grammar Competition</h2>
                            <div className="size">
                                <h3>To encourage grammar practice, Qwack education will organize an online Grammar competition on a regular basis.</h3>
                            </div>
                            <FontAwesomeIcon icon={faHandPointRight}/>
                            <Link to="/competition">Participate Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
