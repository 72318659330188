const simhard = [
  [
      [
          { "id": 3, "word": "BUY", "pairId": 3 },
          { "id": 4, "word": "AWFUL", "pairId": 4 },
          { "id": 2, "word": "BEAUTIFUL", "pairId": 2 },
          { "id": 1, "word": "CUNNING", "pairId": 1 },
          { "id": 5, "word": "BLANK", "pairId": 5 }
      ],
      [
          { "id": 2, "word": "BROAD", "pairId": 2 },
          { "id": 3, "word": "CHANGE", "pairId": 3 },
          { "id": 4, "word": "SMALL", "pairId": 4 },
          { "id": 1, "word": "DELICATE", "pairId": 1 },
          { "id": 5, "word": "DESIRE", "pairId": 5 }
      ],
      [
          { "id": 3, "word": "CONTAMINATE", "pairId": 3 },
          { "id": 1, "word": "EAGER", "pairId": 1 },
          { "id": 4, "word": "EVIL", "pairId": 4 },
          { "id": 5, "word": "LION'S SHARE", "pairId": 5 },
          { "id": 2, "word": "DEAD", "pairId": 2 }
      ],
      [
          { "id": 2, "word": "LAMENT", "pairId": 2 },
          { "id": 4, "word": "BEHAVE", "pairId": 4 },
          { "id": 1, "word": "PREJUDICED", "pairId": 1 },
          { "id": 3, "word": "TERRIBLE", "pairId": 3 },
          { "id": 5, "word": "CONTENTED", "pairId": 5 }
      ],
      [
          { "id": 4, "word": "UNMARRIED", "pairId": 4 },
          { "id": 2, "word": "RATIFY", "pairId": 2 },
          { "id": 5, "word": "WONDERFUL", "pairId": 5 },
          { "id": 3, "word": "OCCUR", "pairId": 3 },
          { "id": 1, "word": "LINEAGE", "pairId": 1 }
      ],
      [
          { "id": 4, "word": "OUTSIDE", "pairId": 4 },
          { "id": 2, "word": "ANSWER", "pairId": 2 },
          { "id": 5, "word": "AGREE", "pairId": 5 },
          { "id": 3, "word": "FRIGID", "pairId": 3 },
          { "id": 1, "word": "CONSCIOUS", "pairId": 1 }
      ],
      [
          { "id": 2, "word": "SLIM", "pairId": 2 },
          { "id": 3, "word": "LEAST", "pairId": 3 },
          { "id": 4, "word": "ALL", "pairId": 4 },
          { "id": 1, "word": "ACCURATE", "pairId": 1 },
          { "id": 5, "word": "SORROW", "pairId": 5 }
      ],
      [
          { "id": 3, "word": "PERFORM", "pairId": 3 },
          { "id": 1, "word": "JUNGLE", "pairId": 1 },
          { "id": 4, "word": "TRIP", "pairId": 4 },
          { "id": 5, "word": "LISTEN", "pairId": 5 },
          { "id": 2, "word": "POOR", "pairId": 2 }
      ],
      [
          { "id": 2, "word": "OLD", "pairId": 2 },
          { "id": 4, "word": "REGULAR", "pairId": 4 },
          { "id": 1, "word": "TORPID", "pairId": 1 },
          { "id": 3, "word": "FORGIVE", "pairId": 3 },
          { "id": 5, "word": "DEFY", "pairId": 5 }
      ],
      [
          { "id": 4, "word": "PAN", "pairId": 4 },
          { "id": 2, "word": "SHY", "pairId": 2 },
          { "id": 5, "word": "BLAZE", "pairId": 5 },
          { "id": 3, "word": "PLENTIFUL", "pairId": 3 },
          { "id": 1, "word": "NEUTRAL", "pairId": 1 }
      ]
  ],

  [
      {
        1: {
          name: "CLEVER",
          items: [],
        },
        2: {
          name: "PRETTY",
          items: [],
        },
        3: {
          name: "PURCHASE",
          items: [],
        },
        4: {
          name: "TERRIBLE",
          items: [],
        },
        5: {
          name: "EMPTY",
          items: [],
        },
      },
      {
        1: {
          name: "FRAGILE",
          items: [],
        },
        2: {
          name: "WIDE",
          items: [],
        },
        3: {
          name: "TRANSFORM",
          items: [],
        },
        4: {
          name: "LITTLE",
          items: [],
        },
        5: {
          name: "URGE",
          items: [],
        },
      },
      {
        1: {
          name: "KEEN",
          items: [],
        },
        2: {
          name: "LIFELESS",
          items: [],
        },
        3: {
          name: "POLLUTE",
          items: [],
        },
        4: {
          name: "BAD",
          items: [],
        },
        5: {
          name: "BIG SHARE",
          items: [],
        },
      },
      {
        1: {
          name: "OPINIONATED",
          items: [],
        },
        2: {
          name: "MOURN",
          items: [],
        },
        3: {
          name: "DREADFUL",
          items: [],
        },
        4: {
          name: "ACT",
          items: [],
        },
        5: {
          name: "SATISFIED",
          items: [],
        },
      },
      {
        1: {
          name: "ANCESTRY",
          items: [],
        },
        2: {
          name: "APPROVE",
          items: [],
        },
        3: {
          name: "HAPPEN",
          items: [],
        },
        4: {
          name: "SINGLE",
          items: [],
        },
        5: {
          name: "MARVELOUS",
          items: [],
        },
      },
      {
        1: {
          name: "AWARE",
          items: [],
        },
        2: {
          name: "RESPONSE",
          items: [],
        },
        3: {
          name: "FREEZING",
          items: [],
        },
        4: {
          name: "EXTERIOR",
          items: [],
        },
        5: {
          name: "CONSENT",
          items: [],
        },
      },
      {
        1: {
          name: "CORRECT",
          items: [],
        },
        2: {
          name: "THIN",
          items: [],
        },
        3: {
          name: "FEWEST",
          items: [],
        },
        4: {
          name: "EVERY",
          items: [],
        },
        5: {
          name: "WOE",
          items: [],
        },
      },
      {
        1: {
          name: "FOREST",
          items: [],
        },
        2: {
          name: "DESTITUTE",
          items: [],
        },
        3: {
          name: "ACT",
          items: [],
        },
        4: {
          name: "JOURNEY",
          items: [],
        },
        5: {
          name: "HEAR",
          items: [],
        },
      },
      {
        1: {
          name: "LAZY",
          items: [],
        },
        2: {
          name: "ANCIENT",
          items: [],
        },
        3: {
          name: "PARDON",
          items: [],
        },
        4: {
          name: "ROUTINE",
          items: [],
        },
        5: {
          name: "RESIST",
          items: [],
        },
      },
      {
        1: {
          name: "IMPARTIAL",
          items: [],
        },
        2: {
          name: "TIMID",
          items: [],
        },
        3: {
          name: "AMPLE",
          items: [],
        },
        4: {
          name: "POT",
          items: [],
        },
        5: {
          name: "FIRE",
          items: [],
        },
      }
    ]
]
export { simhard }