import React from 'react';
import './loader.scss'

function Loader() {
    return (
        <div className="loader-container">
            <div className="loader-cube">
                <div className="loader-sides">
                    <div className="loader-top"></div>
                    <div className="loader-right"></div>
                    <div className="bottom"></div>
                    <div className="left"></div>
                    <div className="front"></div>
                    <div className="back"></div>
                </div>
            </div>
        </div>
    );
}

export default Loader;