const simease = [
    [
        [
            { "id": 3, "word": "AFRAID", "pairId": 3 },
            { "id": 4, "word": "AUTOMOBILE", "pairId": 4 },
            { "id": 2, "word": "BIG", "pairId": 2 },
            { "id": 1, "word": "BLANK", "pairId": 1 },
            { "id": 5, "word": "BUNNY", "pairId": 5 }
        ],
        [
            { "id": 2, "word": "CAP", "pairId": 2 },
            { "id": 3, "word": "CENTRE", "pairId": 3 },
            { "id": 4, "word": "COUCH", "pairId": 4 },
            { "id": 1, "word": "EVIL", "pairId": 1 },
            { "id": 5, "word": "FAMOUS", "pairId": 5 }
        ],
        [
            { "id": 3, "word": "FUNNY", "pairId": 3 },
            { "id": 1, "word": "GARBAGE", "pairId": 1 },
            { "id": 4, "word": "GLOOMY", "pairId": 4 },
            { "id": 5, "word": "HAPPY", "pairId": 5 },
            { "id": 2, "word": "HIDE", "pairId": 2 }
        ],
        [
            { "id": 2, "word": "HOUSE", "pairId": 2 },
            { "id": 4, "word": "ILL", "pairId": 4 },
            { "id": 1, "word": "IDEA", "pairId": 1 },
            { "id": 3, "word": "JOG", "pairId": 3 },
            { "id": 5, "word": "LISTEN", "pairId": 5 }
        ],
        [
            { "id": 4, "word": "LITTLE", "pairId": 4 },
            { "id": 2, "word": "LOOK", "pairId": 2 },
            { "id": 5, "word": "MAD", "pairId": 5 },
            { "id": 3, "word": "MOTHER", "pairId": 3 },
            { "id": 1, "word": "NEAT", "pairId": 1 }
        ],
        [
            { "id": 3, "word": "PRESENT", "pairId": 3 },
            { "id": 4, "word": "QUICK", "pairId": 4 },
            { "id": 2, "word": "REST", "pairId": 2 },
            { "id": 1, "word": "ROCK", "pairId": 1 },
            { "id": 5, "word": "RUG", "pairId": 5 }
        ],
        [
            { "id": 2, "word": "ASK", "pairId": 2 },
            { "id": 3, "word": "LARGE", "pairId": 3 },
            { "id": 4, "word": "EXIT", "pairId": 4 },
            { "id": 1, "word": "INFANT", "pairId": 1 },
            { "id": 5, "word": "GOOD", "pairId": 5 }
        ],
        [
            { "id": 3, "word": "CORRECT", "pairId": 3 },
            { "id": 1, "word": "CARE", "pairId": 1 },
            { "id": 4, "word": "DAMAGE", "pairId": 4 },
            { "id": 5, "word": "ALIKE", "pairId": 5 },
            { "id": 2, "word": "DOCUMENT", "pairId": 2 }
        ],
        [
            { "id": 2, "word": "DIFFICULT", "pairId": 2 },
            { "id": 4, "word": "ONE", "pairId": 4 },
            { "id": 1, "word": "RESPECT", "pairId": 1 },
            { "id": 3, "word": "OLD", "pairId": 3 },
            { "id": 5, "word": "END", "pairId": 5 }
        ],
        [
            { "id": 4, "word": "RAISE", "pairId": 4 },
            { "id": 2, "word": "FALSE", "pairId": 2 },
            { "id": 5, "word": "KEEP", "pairId": 5 },
            { "id": 3, "word": "GET", "pairId": 3 },
            { "id": 1, "word": "ABOVE", "pairId": 1 }
        ]
    ],

    [
        {
            1: {
                name: "EMPTY",
                items: []
            },
            2: {
                name: "LARGE",
                items: []
            },
            3: {
                name: "SCARED",
                items: []
            },
            4: {
                name: "CAR",
                items: []
            },
            5: {
                name: "HARE",
                items: []
            },
        },
        {
            1: {
                name: "WICKED",
                items: []
            },
            2: {
                name: "HAT",
                items: []
            },
            3: {
                name: "MIDDLE",
                items: []
            },
            4: {
                name: "SOFA",
                items: []
            },
            5: {
                name: "POPULAR",
                items: []
            },
        },
        {
            1: {
                name: "TRASH",
                items: []
            },
            2: {
                name: "CONCEAL",
                items: []
            },
            3: {
                name: "SILLY",
                items: []
            },
            4: {
                name: "SAD",
                items: []
            },
            5: {
                name: "JOYFUL",
                items: []
            },
        },
        {
            1: {
                name: "NOTION",
                items: []
            },
            2: {
                name: "HABITAT",
                items: []
            },
            3: {
                name: "RUN",
                items: []
            },
            4: {
                name: "AILING",
                items: []
            },
            5: {
                name: "HEAR",
                items: []
            },
        },
        {
            1: {
                name: "CLEAN",
                items: []
            },
            2: {
                name: "STARE",
                items: []
            },
            3: {
                name: "MOM",
                items: []
            },
            4: {
                name: "MINI",
                items: []
            },
            5: {
                name: "ENRAGED",
                items: []
            },
        },
        {
            1: {
                name: "STONE",
                items: []
            },
            2: {
                name: "RELAX",
                items: []
            },
            3: {
                name: "AWARD",
                items: []
            },
            4: {
                name: "FAST",
                items: []
            },
            5: {
                name: "CARPET",
                items: []
            },
        },
        {
            1: {
                name: "BABY",
                items: []
            },
            2: {
                name: "ENQUIRE",
                items: []
            },
            3: {
                name: "BIG",
                items: []
            },
            4: {
                name: "LEAVE",
                items: []
            },
            5: {
                name: "FINE",
                items: []
            },
        }
        ,
        {
            1: {
                name: "PROTECTION",
                items: []
            },
            2: {
                name: "RECORD",
                items: []
            },
            3: {
                name: "RIGHT",
                items: []
            },
            4: {
                name: "HURT",
                items: []
            },
            5: {
                name: "SAME",
                items: []
            },
        },
        {
            1: {
                name: "HONOR",
                items: []
            },
            2: {
                name: "HARD",
                items: []
            },
            3: {
                name: "ANCIENT",
                items: []
            },
            4: {
                name: "SINGLE",
                items: []
            },
            5: {
                name: "FINISH",
                items: []
            },
        },
        {
            1: {
                name: "OVER",
                items: []
            },
            2: {
                name: "UNTRUE",
                items: []
            },
            3: {
                name: "RECIEVE",
                items: []
            },
            4: {
                name: "LIFT",
                items: []
            },
            5: {
                name: "HOLD",
                items: []
            },
        }
    ]
]
export { simease }