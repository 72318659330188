import React, { useEffect, useState } from 'react'
import './CompPlaying.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import CompWait from '../Helper/compwait'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loading';
import useApi from '../../Hooks/useApi'
import axios from 'axios'
export default function Playing({ setMagicBar, setMagicNumber}) {
    setMagicBar(false);
setMagicNumber(4)
    const navigate = useNavigate();
    // get user from local storage
    const user = JSON.parse(localStorage.getItem('comp3User'));
    if (user === null) {
        console.log("user" + user);
        // navigate to home if not logged in
        window.location.href = "/competition/participate";
        // navigate('./competition');
        // alert('You are not registered for the competition');
    }
    // get url params
    const urlParams = new URLSearchParams(window.location.search);
    // const standard = urlParams.get('standard');
    const standard = user.standard;
    const { data, error, loading } = useApi(`${process.env.REACT_APP_SERVER}/api/comp-questions?filters[standard][$eq]=${standard}&filters[date][$eq]=20230122`);
    const questions = loading ? [] : data.data[0].attributes.questions;
    // console.log(CompWait());
    const wait = CompWait();
    const time = 40 - (wait * -1) % 40;
    const currentQuestion = -1 * Math.floor(wait / 40);
    const [timeTaken, setTimeTaken] = useState((localStorage.getItem('timeTaken')) || 0);
    const answer = ['a', 'b', 'c', 'd'];
    // get answer from local storage
    const answers = JSON.parse(localStorage.getItem('comp3Answers')) || [...Array(questions.length)].map(e => null);
    const isAttempted = answers[currentQuestion - 1] !== null;

    useEffect(() => {
        // console.log(answers);

        // set answers to local storage
    }, [answers]);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (wait >= 0) {
            navigate('./../waiting');
        } else if (currentQuestion > questions.length) {
            axios.post(`${process.env.REACT_APP_SERVER}/api/comp/set-answer`, {
                answers,
                timeTaken,
                id : user.id
            })
            navigate('./../completed');
        }
    }, [wait]);

    return (
        <>
            {
                loading ? (
                    <Loader />
                ) : (

                    <div className='CompPlaying'>

                        <div className={`timer ${time < 10 && `less${time}`}`}>
                            <FontAwesomeIcon icon={faClock} />
                            {time}
                        </div>
                        <div className="cont">
                        <div className="head">
                                            
                                                <div className={`option`}>
                                                    <h3>Choose the correct Option</h3>
                                                </div>
                                        </div>
                            <div className="question-wrapper" style={{ transform: `translateX(-${(currentQuestion - 1) * 100}vw)` }}>
                                {questions.map((question, index) => (
                                    <div className="box">
                                        <div className="question" key={index}>
                                            <div className="number">{index + 1}</div>
                                            <h2>{question.question}</h2>
                                        </div>
                                        <div className="answers">
                                            {question.options.map((option, index1) => (
                                                <div className={`option ${answers[currentQuestion - 1] === index1 && 'answerd'}`} key={index1} onClick={() => {
                                                    if (!isAttempted) {
                                                        answers[index] = index1;
                                                        console.log(answers);
                                                        localStorage.setItem('comp3Answers', JSON.stringify(answers));
                                                        const timeTaken = localStorage.getItem('timeTaken') || 0;
                                                        localStorage.setItem('timeTaken', parseInt(timeTaken) + time);
                                                    }
                                                }
                                                }>
                                                    <div className="abcd">{answer[index1]}.</div>
                                                    <h3>{option}</h3>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                                )}
                            </div>
                        </div>

                    </div>
                )
            }
        </>
    )
}
