import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import useApi from '../../Hooks/useApi'
import Loader from '../Loading'
import './WordCard.scss'
import swoosh from '../../assets/audio/swoosh.mp3';
import error from '../../assets/audio/error.mp3';
import correct from '../../assets/audio/correct.mp3';
import loss from '../../assets/audio/loss.mp3';
import victory from '../../assets/audio/victory.mp3';
import useSound from 'use-sound';
// import { Link } from 'react-router-dom';
// import Modal from '../Modal'
import { useNavigate } from 'react-router-dom'

export default function WordCard({ user , setMagicBar, setMagicNumber}) {
    const queryParams = new URLSearchParams(window.location.search)
    setMagicBar(true);
    setMagicNumber(3)
    
    const src = queryParams.get("src")
    const difficulty = queryParams.get("difficulty")
    const querylevel = queryParams.get("level")
    const navigate = useNavigate();
    const { loading, data } = useApi(`${process.env.REACT_APP_SERVER}/api/word-cards${src === "s" ? '' : '1'}?populate=*&filters[difficulty][$eq]=${difficulty}`)
    const link = `${process.env.REACT_APP_SERVER}/api/word-cards${src === "s" ? '' : '1'}?populate=*&filters[difficulty][$eq]=${difficulty}`
    console.log(link);
    console.log(process.env.REACT_APP_SERVER);
    const [words, setWords] = useState([])
    const [turns, setTurn] = useState(20)
    const [matches, setMatches] = useState(0)
    const [choiceOne, setChoiceOne] = useState(null)
    const [choiceTwo, setChoiceTwo] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [isGameOver, setIsGameOver] = useState(false)
    const [swoosh1] = useSound(swoosh);
    const [error1] = useSound(error);
    const [correct1] = useSound(correct);
    const [loss1] = useSound(loss);
    const [victory1] = useSound(victory);

    // const [showModal, setShowModal] = useState(false)
    const role = localStorage.getItem('role')
    const [level, setLevel] = useState(querylevel);
    const freeLevels = ["1", "2"]
    const wordsdata = loading ? [] : level ? data.data[level - 1].attributes.word : []
    const levelchange = (e) => {
        const level = e.target.value;
        setLevel(level);
    }
    useEffect(() => {
        window.onbeforeunload = function () {
            return "Dude, are you sure you want to leave? Think of the kittens!";
        }
    }, [])
    const shuffleCards = () => {
        if (user && role === "\"member\"" || freeLevels.includes(level)) {
            if (level) {
                swoosh1()
                const shuffledWords = [...wordsdata].sort(() => Math.random() - 0.5)
                    .map((card, i) => ({ ...card, id: i }))
                setWords(shuffledWords)
                setTurn(20)
                setChoiceOne(null)
                setChoiceTwo(null)
                setIsGameOver(false)
                setMatches(0)
            } else {
                alert('select a level')
            }
        } else if (user) {
            navigate('/member')

        } else {
            navigate('/login')
        }
    }
    const resetcard = () => {
        setChoiceOne(null)
        setChoiceTwo(null)
        setTurn(turns - 1)
        setDisabled(false)
    }

    useEffect(() => {
        if (choiceOne && choiceTwo) {
            setDisabled(true)
            if (choiceOne.pairId === choiceTwo.pairId) {
                correct1()
                setWords(oldwords => {
                    return oldwords.map((word) => {
                        if (word.pairId === choiceOne.pairId) {
                            setMatches(matches + 2)
                            return { ...word, matched: true }
                        } else {
                            return word
                        }
                    })
                })
                resetcard()

            } else {
                setTimeout(() => {
                    resetcard();
                    error1()
                }, 1000)

            }
        }
        if (turns === 0) {
            setTimeout(() => {
                setIsGameOver(true)
                // setShowModal(true)
                loss1()
                navigate(`./score?src=${src}&difficulty=${difficulty}&level=${level}&t1=You Loose&t2=in vocabulary games ${src === "s" ? 'Synonyms' : 'Antonyms'} difficulty ${difficulty} level ${level}&meta=${data.data.length}`)
            }, 1000)
        }
        if (matches === 12) {
            setTimeout(() => {
                victory1()
                navigate(`./score?src=${src}&difficulty=${difficulty}&level=${level}&t1=You Won&t2=in vocabulary games ${src === "s" ? 'Synonyms' : 'Antonyms'} difficulty ${difficulty} level ${level}&meta=${data.data.length}`)
                setIsGameOver(true)
            }, 1005)
        }


    }, [choiceOne, choiceTwo, turns])

    const handleClick = (card) => {
        disabled ? console.log() : choiceOne ? setChoiceTwo(card) : setChoiceOne(card)
    }

    return (
        <>
            {loading ? (<Loader />) : (<div className="wordcard">
            {/* <Modal verified={showModal} redirect="/">
                <div className="modal-main">
                    <div className="modal-header">
                        <h1>Thank you for Siging Up</h1>
                    </div>
                    <div className="modal-body">
                        <p>Now you can become a member to gain full access to all the benefits</p>
                    </div>
                    <div className="modal-cta">
                        <a href={`https://pages.razorpay.com/pl_KQ2olF30knVH2q/view?phone=${window.localStorage.getItem('phone') && window.localStorage.getItem('phone').replaceAll('"', '')}`} className="btn btn-primary">Become a Member</a>
                    </div>
                    <div className="modal-footer">
                        <Link to="/" onClick={() => setShowModal(false)}>I will do it later</Link>
                    </div>
                </div>

            </Modal> */}
                <h1>
                    Wordcards
                </h1>
                <p>
                    {src === "s" ? 
                    'Flip 2 cards and find the Similar Words' 
                    : 
                    'Flip 2 cards and find the Opposite Words'}
                    
                </p>
                <p>Turns left: {turns} </p>
                <label className="select" htmlFor="slct">
                    <select id="slct" required="required" onChange={levelchange} value={level}>
                        <option value="" disabled="disabled" selected="selected">
                            Select level
                        </option>
                        {data.data.map((l, i) => (
                            <option value={i + 1}>{1 + i} {freeLevels.includes(`${(i+1)}`) && "(Free)"}</option>

                        ))}
                        {/* <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option> */}
                    </select>
                    <svg>
                        <use xlinkHref="#select-arrow-down" />
                    </svg>
                </label>
                <svg class="sprites">
                    <symbol id="select-arrow-down" viewBox="0 0 10 6">
                        <polyline points="1 1 5 5 9 1"></polyline>
                    </symbol>
                </svg>
                <button onClick={shuffleCards}>{'New Game'} {user && role === "\"member\"" || freeLevels.includes(level) ? '' : <i className="fa fa-lock" aria-hidden="true"></i>}</button>
                <div className={`cards ${isGameOver ? 'over' : ''}`}>
                    {words.map((word) => (
                        <div className={`gamecard ${(word === choiceOne || word === choiceTwo || word.matched) ? 'flipped' : ''} ${word.matched ? 'matched' : ''}`} key={word.id}>
                            <div className="cardfront">{word.word}</div>
                            <div className="cardback" onClick={() => handleClick(word)}></div>
                        </div>
                    ))}
                </div>
            </div>)}
        </>
    )
}
