import React from 'react'
import { useState } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import './WordOftheDay.scss'
import arrow from '../../assets/images/arrow.png'
import useApi from '../../Hooks/useApi'
import useToggle from '../../Hooks/useToogle';
import Drawer from '../Mobile/Drawer';

export default function Word({user, setMagicNumber, setMagicBar}) {
  setMagicBar(true);
setMagicNumber(3)
  const [drawer, drawerAction] = useToggle(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const date_1 = new Date('01/02/2023');
  const date_2 = new Date();

  const days1 = (date_1, date_2) => {
    const difference = date_2.getTime() - date_1.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24)) - 1;
    return TotalDays;
  }

  const link = `${process.env.REACT_APP_SERVER}/api/words?pagination[start]=${days1(date_1,date_2)}&pagination[limit]=7`
  const { loading, data } = useApi(link)
  const temp = loading ? [] : data.data
  const handleClick = (way) => {
    way === "left"
      ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : words.length - 1)
      : setCurrentSlide(currentSlide < words.length - 1 ? currentSlide + 1 : 0);
  };
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dow = new Date().getDay();

  const reverseArray = (input) => {
    var ret = [];
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  const words = reverseArray(temp)

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} user={user}/>
      <div className='WordoftheDay'>
        <div className='wave wave1'></div>
        <div className='wave wave2'></div>
        <div className='wave wave3'></div>
        <div className='sun'></div>
        <div className="heading"><h1>Word of the day</h1></div>
        <div className="flex">

          <div className="slider" style={{ transform: `translateX(-${currentSlide * 100}vw)` }}>
            {words.map((d, i) => (
              <div className="vocab-container" key={i}>
                <div className="item">
                  <div className="day">{i === 0 ? 'Today' : i === 1 ? 'Yesterday' : (dow - i) >= 0 ? days[dow - i] : days[7 + (dow - i)]}</div>
                  <div className="wm">
                    <div className='word'>
                      <h1>{d.attributes.word}</h1>
                    </div>
                    <div className='meaning'><p>{d.attributes.meaning}</p></div>
                  </div>
                  <div className='type'>[{d.attributes.type}]</div>
                  <div className='sentence'>Use:</div>
                  <div className='sentence'>{d.attributes.sentence}</div>
                </div>
              </div>
            ))}
          </div>
         
        </div>
        <img
          src={arrow}
          className="arrow left"
          alt=""
          onClick={() => handleClick("left")}
        />
        <img
          src={arrow}
          className="arrow right"
          alt=""
          onClick={() => handleClick()}
        />

      </div>
      <Footer />
    </>
  )
}
