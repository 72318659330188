import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import './WorksheetSingle.scss'
import { useParams } from 'react-router-dom'
import useApi from '../../Hooks/useApi'
import useToggle from '../../Hooks/useToogle';
import Drawer from '../Mobile/Drawer';
import Loader from '../Loading'
import { Link } from 'react-router-dom'

export default function WorksheetSingle({ user, setMagicNumber, setMagicBar }) {
setMagicBar(true);
setMagicNumber(1)
  const { name } = useParams();
  const link = `${process.env.REACT_APP_SERVER}/api/worksheets?filters[topic][$eq]=${name}&populate=Worksheet`
  const [drawer, drawerAction] = useToggle(false);
  const role = user ? localStorage.getItem('role') : null;
  console.log(role);
  const { loading, data } = useApi(link)
  const worksheets = loading ? [] : data.data[0]
  const onClickHandler = async (id) => {
    const counter_data = await fetch(`${process.env.REACT_APP_SERVER}/api/worksheet/newread/${id}?topic=${worksheets.id}`);
  }

  return (
    <>
      {!loading ? (
        <>
          <Drawer drawer={drawer} action={drawerAction.toggle} />
          <Header action={drawerAction.toggle} user={user} />
          <div className="WorksheetSingle">
            <div className="head">
              <div className="heading">
                <Link to='/worksheets'>
                <h1>Grammar Worksheets</h1>
                </Link>
              </div>
              <div className="subhead">
                {" "}
                <h2>{worksheets.attributes.topic}</h2>
              </div>
              <div className="indications">
                <div className="indication">
                  Suitable for Age : 7 to 8 years
                </div>
                <div className="indication med">
                  Suitable for Age : 9 to 10 years
                </div>
              </div>
              <div className="indication-bulb">
                  <i
                    className="fa fa-lightbulb"
                    aria-hidden="true"
                    style={{ marginRight: "5px" }}
                  ></i> - Answer Key</div>
              {/* <div className="desc">{worksheets.attributes.Description}</div> */}
            </div>

            <div className="worksheet-wrapper">
              <div className="link-container">
                <div className="worksheet-topic">
                  <h3>Concept Sheets</h3>
                  {worksheets.attributes.Worksheet.length > 0
                    ? worksheets.attributes.Worksheet.map((pdf, i) => {
                      if (pdf.type === "concept") {
                        return (
                          <div className="links mate" key={i}>
                            {user && role === "\"member\"" || name === 'Nouns' ? (
                              <>
                                <a
                                  className="view"
                                  href={`${process.env.REACT_APP_FILE_MANAGER}/${worksheets.attributes.topic}/Concept/${pdf.file_name}.pdf`}
                                  target="_blank"
                                  onClick={() => onClickHandler(pdf.id)}
                                >
                                  {pdf.file_name}
                                </a>
                                <a
                                  className="download"
                                  download={`pdfs.pdf`}
                                  href={`${process.env.REACT_APP_FILE_MANAGER}/${worksheets.attributes.topic}/Concept/${pdf.file_name}.pdf`}
                                  onClick={() => onClickHandler(pdf.id)}
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </>
                            ) : (
                              <>
                                <Link to= {!user ?  "/login" : "/member"}
                                  className="view"
                                >{pdf.file_name}</Link>

                                <Link to= {!user ?  "/login" : "/member"}
                                  className="download"
                                >
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </>
                            )}
                          </div>
                        );
                      }
                      return <div></div>;
                    })
                    : ""}
                </div>
                {/* <div className='worksheet-topic'>
                                <h3>Practice Worksheets</h3>
                                <div className='links'>IDENTIFY PROPER NOUN. 01 <i className="fa fa-download" aria-hidden="true"></i></div>
                                <div className='links'>IDENTIFY PROPER NOUN. 01 <i className="fa fa-download" aria-hidden="true"></i></div>
                                <div className='links'>IDENTIFY PROPER NOUN. 01 <i className="fa fa-download" aria-hidden="true"></i></div>
                            </div> */}
              </div>
              <div className="link-container">
                <div className="worksheet-topic">
                  <h3>Practice Worksheets</h3>
                  {worksheets.attributes.Worksheet.length > 0
                    ? worksheets.attributes.Worksheet.map((pdf, i) => {
                      if (pdf.type === "practice easy") {
                        return (
                          <div className="links" key={i}>
                            {user && role === "\"member\"" || name === 'Nouns' ? (
                              <>
                                <a
                                  href={`${process.env.REACT_APP_FILE_MANAGER}/${worksheets.attributes.topic}/Practice/Easy/${pdf.file_name}.pdf`}
                                  target="_blank"
                                  className="view"
                                  onClick={() => onClickHandler(pdf.id)}
                                >
                                  {pdf.file_name}
                                </a>
                                <a
                                  download={`pdfs.pdf`}
                                  className="download"
                                  href={`${process.env.REACT_APP_FILE_MANAGER}/${worksheets.attributes.topic}/Practice/Easy/${pdf.file_name}.pdf`}
                                  onClick={() => onClickHandler(pdf.id)}
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                                <a href={`${process.env.REACT_APP_FILE_MANAGER}/${worksheets.attributes.topic}/Practice/Easy/${pdf.file_name} answers.pdf`} className="bulb">
                                  <i
                                    className="fa fa-lightbulb"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </>
                            ) : (
                              <>
                                <Link to={!user ?  "/login" : "/member"} className="view">{pdf.file_name}</Link>

                                <Link to={!user ?  "/login" : "/member"} className="download">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </>
                            )}
                          </div>
                        );
                      }
                      return <div></div>;
                    })
                    : ""}
                  {worksheets.attributes.Worksheet.length > 0
                    ? worksheets.attributes.Worksheet.map((pdf, i) => {
                      if (pdf.type === "practice hard") {
                        return (
                          <div className="links med" key={i}>
                            {user && role === "\"member\"" || name === 'Nouns' ? (
                              <>
                                <a
                                  className="view"
                                  href={`${process.env.REACT_APP_FILE_MANAGER}/${worksheets.attributes.topic}/Practice/Medium/${pdf.file_name}.pdf`}
                                  target="_blank"
                                  onClick={() => onClickHandler(pdf.id)}
                                >
                                  {pdf.file_name}
                                </a>
                                <a
                                  className="download"
                                  download={`pdfs.pdf`}
                                  href={`${process.env.REACT_APP_FILE_MANAGER}/${worksheets.attributes.topic}/Practice/Medium/${pdf.file_name}.pdf`}
                                  onClick={() => onClickHandler(pdf.id)}
                                >
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                                <a href={`${process.env.REACT_APP_FILE_MANAGER}/${worksheets.attributes.topic}/Practice/Medium/${pdf.file_name} answers.pdf`} className="bulb">
                                  <i
                                    className="fa fa-lightbulb"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </>
                            ) : (
                              <>
                                <Link to={!user ?  "/login" : "/member"} className="view">{pdf.file_name}</Link>

                                <Link to={!user ?  "/login" : "/member"} className="download">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </>
                            )}
                          </div>
                        );
                      }
                      return <div></div>;
                    })
                    : ""}
                </div>
                {/* <div className='worksheet-topic'>
                                <h3>Practice Worksheets</h3>
                                <div className='links med'>IDENTIFY PROPER NOUN. And and AND 01 <i className="fa fa-download" aria-hidden="true"></i></div>
                                <div className='links med'>IDENTIFY PROPER NOUN. 01 <i className="fa fa-download" aria-hidden="true"></i></div>
                                <div className='links med'>IDENTIFY PROPER NOUN. 01 <i className="fa fa-download" aria-hidden="true"></i></div>
                            </div> */}
              </div>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
