import React from 'react'
import name from "../../assets/images/Name.png"
import drawing from "../../assets/images/drawing.png"

export default function Intro({ setMagicBar, setMagicNumber}) {
  setMagicBar(false);
  return (
    <div>
      <img src={drawing} alt="name" onClick={() => {
        localStorage.setItem("introed", true);
        // reload
        window.location.reload();
      }}/>
      <img src={name} alt="name" />
    </div>
  )
}
