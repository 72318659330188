const antease = [
    [
        [
            {
                "id": 3,
                "word": "INTELLIGENT",
                "pairId": 3
            },
            {
                "id": 4,
                "word": "PROTECT",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "CALM",
                "pairId": 2
            },
            {
                "id": 1,
                "word": "IN",
                "pairId": 1
            },
            {
                "id": 5,
                "word": "PRESENCE",
                "pairId": 5
            }
        ],
        [
            {
                "id": 2,
                "word": "WET",
                "pairId": 2
            },
            {
                "id": 3,
                "word": "COLD",
                "pairId": 3
            },
            {
                "id": 4,
                "word": "INCREASE",
                "pairId": 4
            },
            {
                "id": 1,
                "word": "PRETTY",
                "pairId": 1
            },
            {
                "id": 5,
                "word": "WIDE",
                "pairId": 5
            }
        ],
        [
            {
                "id": 3,
                "word": "INSIDE",
                "pairId": 3
            },
            {
                "id": 1,
                "word": "CLOUDY",
                "pairId": 1
            },
            {
                "id": 4,
                "word": "INCLUDE",
                "pairId": 4
            },
            {
                "id": 5,
                "word": "PRESENT",
                "pairId": 5
            },
            {
                "id": 2,
                "word": "WHITE",
                "pairId": 2
            }
        ],
        [
            {
                "id": 2,
                "word": "BROTHER",
                "pairId": 2
            },
            {
                "id": 4,
                "word": "HARVEST",
                "pairId": 4
            },
            {
                "id": 1,
                "word": "LIE",
                "pairId": 1
            },
            {
                "id": 3,
                "word": "SADNESS",
                "pairId": 3
            },
            {
                "id": 5,
                "word": "ALL",
                "pairId": 5
            }
        ],
        [
            {
                "id": 4,
                "word": "HANDSOME",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "RURAL",
                "pairId": 2
            },
            {
                "id": 5,
                "word": "BUSY",
                "pairId": 5
            },
            {
                "id": 3,
                "word": "LIGHT",
                "pairId": 3
            },
            {
                "id": 1,
                "word": "SAD",
                "pairId": 1
            }
        ],
        [
            {
                "id": 3,
                "word": "BUY",
                "pairId": 3
            },
            {
                "id": 4,
                "word": "POWERFUL",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "SAME",
                "pairId": 2
            },
            {
                "id": 1,
                "word": "COME",
                "pairId": 1
            },
            {
                "id": 5,
                "word": "PRIVATE",
                "pairId": 5
            }
        ],
        [
            {
                "id": 2,
                "word": "LAUGH",
                "pairId": 2
            },
            {
                "id": 3,
                "word": "FAR",
                "pairId": 3
            },
            {
                "id": 4,
                "word": "EVEN",
                "pairId": 4
            },
            {
                "id": 1,
                "word": "GIVE",
                "pairId": 1
            },
            {
                "id": 5,
                "word": "HOT",
                "pairId": 5
            }
        ],
        [
        {
            "id": 3,
            "word": "NIGHT",
            "pairId": 3
        },
        {
            "id": 1,
            "word": "LIGHT",
            "pairId": 1
        },
        {
            "id": 4,
            "word": "IMPORT",
            "pairId": 4
        },
        {
            "id": 5,
            "word": "OPPOSITE",
            "pairId": 5
        },
        {
            "id": 2,
            "word": "HARD",
            "pairId": 2
        }
        ],
        [
            {
                "id": 2,
                "word": "FAT",
                "pairId": 2
            },
            {
                "id": 3,
                "word": "GAIN",
                "pairId": 3
            },
            {
                "id": 4,
                "word": "FAR",
                "pairId": 4
            },
            {
                "id": 1,
                "word": "FORGET",
                "pairId": 1
            },
            {
                "id": 5,
                "word": "DULL",
                "pairId": 5
            }
        ],
        [
            {
                "id": 2,
                "word": "BEFORE",
                "pairId": 2
            },
            {
                "id": 4,
                "word": "FAITHFUL",
                "pairId": 4
            },
            {
                "id": 1,
                "word": "CLEAN",
                "pairId": 1
            },
            {
                "id": 3,
                "word": "FAMOUS",
                "pairId": 3
            },
            {
                "id": 5,
                "word": "FAIR",
                "pairId": 5
            }
        ]
    ],

    [

        {
            "1": {
                "name": "OUT",
                "items": []
            },
            "2": {
                "name": "EXCITED",
                "items": []
            },
            "3": {
                "name": "SILLY",
                "items": []
            },
            "4": {
                "name": "ATTACK",
                "items": []
            },
            "5": {
                "name": "ABSENT",
                "items": []
            }
        },
        {
            "1": {
                "name": "UGLY",
                "items": []
            },
            "2": {
                "name": "DRY",
                "items": []
            },
            "3": {
                "name": "HOT",
                "items": []
            },
            "4": {
                "name": "DECREASE",
                "items": []
            },
            "5": {
                "name": "NARROW",
                "items": []
            }
        },
        {
            "1": {
                "name": "CLEAR",
                "items": []
            },
            "2": {
                "name": "BLACK",
                "items": []
            },
            "3": {
                "name": "OUTSIDE",
                "items": []
            },
            "4": {
                "name": "EXCLUDE",
                "items": []
            },
            "5": {
                "name": "PAST",
                "items": []
            }
        },
        {
            "1": {
                "name": "STAND",
                "items": []
            },
            "2": {
                "name": "SISTER",
                "items": []
            },
            "3": {
                "name": "HAPPINESS",
                "items": []
            },
            "4": {
                "name": "PLANT",
                "items": []
            },
            "5": {
                "name": "NONE",
                "items": []
            }
        },
        {
            "1": {
                "name": "HAPPY",
                "items": []
            },
            "2": {
                "name": "URBAN",
                "items": []
            },
            "3": {
                "name": "DARK",
                "items": []
            },
            "4": {
                "name": "UGLY",
                "items": []
            },
            "5": {
                "name": "LAZY",
                "items": []
            }
        },
        {
            "1": {
                "name": "GO",
                "items": []
            },
            "2": {
                "name": "DIFFERENT",
                "items": []
            },
            "3": {
                "name": "SELL",
                "items": []
            },
            "4": {
                "name": "WEAK",
                "items": []
            },
            "5": {
                "name": "PUBLIC",
                "items": []
            }
        },
        {
            "1": {
                "name": "TAKE",
                "items": []
            },
            "2": {
                "name": "CRY",
                "items": []
            },
            "3": {
                "name": "NEAR",
                "items": []
            },
            "4": {
                "name": "ODD",
                "items": []
            },
            "5": {
                "name": "COLD",
                "items": []
            }
        },
        {
            "1": {
                "name": "DARK",
                "items": []
            },
            "2": {
                "name": "EASY",
                "items": []
            },
            "3": {
                "name": "DAY",
                "items": []
            },
            "4": {
                "name": "EXPORT",
                "items": []
            },
            "5": {
                "name": "SAME",
                "items": []
            }
        },



        {
            1: {
                name: "LEAD",
                items: []
            },
            2: {
                name: "MANY",
                items: []
            },
            3: {
                name: "STALE",
                items: []
            },
            4: {
                name: "DISAGREE",
                items: []
            },
            5: {
                name: "NATURAL",
                items: []
            },
        },
        {
            1: {
                name: "REMEMBER",
                items: []
            },
            2: {
                name: "THIN",
                items: []
            },
            3: {
                name: "LOSS",
                items: []
            },
            4: {
                name: "NEAR",
                items: []
            },
            5: {
                name: "WET",
                items: []
            },
        },
        {
            1: {
                name: "DIRTY",
                items: []
            },
            2: {
                name: "AFTER",
                items: []
            },
            3: {
                name: "ORDINARY",
                items: []
            },
            4: {
                name: "UNFAITHFUL",
                items: []
            },
            5: {
                name: "UNFAIR",
                items: []
            },
        }
    ]
]
export { antease }