import React from 'react'
import './Maintenance.scss'
// import logo
import logo from '../../assets/images/logo.png'

export default function Maintenance() {
  return (
    <div className='mainnn'>
      <section className="container">
        <div className="content">
          <div className="logo-container">
            <div className="header">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="message">
            <h1>Down for maintenance</h1>
            <p>Please check back shortly </p>
          </div>
        </div>
      </section>

    </div>
  )
}
