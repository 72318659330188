import React from 'react'
import Header from '../Header'
import Drawer from '../Mobile/Drawer'
import useToggle from '../../Hooks/useToogle'
// import Header from '../Header'
import './privacy.scss'
import axios from 'axios'
import { useEffect } from 'react'

export default function About({ user, setUser, setMagicBar, setMagicNumber }) {
    setMagicBar(true);
setMagicNumber(0)
    const [drawer, drawerAction] = useToggle(false);
    // useEffect(() => {
    //   axios
    //   .post('http://localhost:1337/api/auth/local/register', {
    //     username: 'ra000afat',
    //     email: 'u520000er@strapi.io',
    //     password: 'strapiPassword',
    //   })
    //   .then(response => {
    //     // Handle success.
    //     console.log('Well done!');
    //     console.log('User profile', response.data.user);
    //     console.log('User token', response.data.jwt);
    //   })
    //   .catch(error => {
    //     // Handle error.
    //     console.log('An error occurred:', error.response);
    //   });}, [])

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} user={user} setUser={setUser} />
            <div className='privacy'><h1>Privacy Policy</h1>
                <p>Welcome to QWACK EDUCATION.
                    We provide educational games, Worksheets,
                    and Quizzes for kids.
                    We at QWACK EDUCATION operate this website
                    ("Website") and is are committed to protecting
                    the privacy and security
                    of its online visitors—especially the privacy of children who visit this site.
                    We are sensitive to family and educator concerns about children's safety and information related to them.
                    We want you to know about and understand the measures we have taken to keep the websites and online services
                    provided by QWACK EDUCATION a safe and secure environment for everyone.
                    We would like every visitor to know that we do not knowingly collect any personally identifiable information
                    from children.
                </p>
                <p>We encourage our visitors to read through and understand our privacy statement. We may update it from time to
                    time to address new issues or reflect changes on our Website. Please refer to this privacy statement regularly.
                    QWACK EDUCATION reserves the right to revise this privacy statement at any time and such revisions shall be
                    effective immediately upon notice thereof. Notice may be given by any means including, but not limited to,
                    posting the revised privacy statement on this Website. Any use of our site by you subsequent to such notice
                    shall be deemed to constitute acceptance by you of such revision.</p>
                <p>If you have any questions or concerns about this privacy statement, or your dealings with QWACK EDUCATION,
                    please:</p>
                <ol>
                    <li>Contact us – info@qwackedu.com</li>
                </ol>
                <h2>HELPFUL EXPLANATIONS</h2>
                <p>An IP address is a number that is assigned automatically to your computer whenever you use the World Wide Web.
                    Web
                    servers -- the big computers that "serve up" Web pages -- automatically identify your computer by its IP
                    address.</p>
                <p>Personal information means information that would identify you, directly or indirectly, such as your name,
                    mailing
                    address, email address or telephone number.</p>
                <p>Anonymized information means information that does not or could not reasonably be used to identify a specific
                    individual.</p>
                <p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons)
                    determines the purposes for which and the manner in which any personal information and anonymized information
                    are, or
                    are to be, processed.</p>
                <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
                <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the
                    Data
                    Controller.</p>
                <p>We may use the services of various Service Providers in order to process your data more effectively</p>
                <h3>INFORMATION WE COLLECT FROM YOU</h3>
                <p>We collect information from you, including some personally identifiable information, when you visit our website,
                    register on our site, use our site content or participate in a contest.</p>
                <p>We do collect anonymized information regarding site activity from users.</p>
                <p>This Website may contain games where free form text entry is permitted, but this content is only visible to the
                    person
                    playing the game.</p>
                <p>We collect anonymized information from your computer or device such as browser type, operating system, referring
                    pages,
                    pages visited, time spent on a particular page and IP address. IP addresses are automatically anonymized.</p>
                <p>When you use our website, your IP address and cookies are automatically received and recorded.</p>
                <p>When making a purchase or registering as a user on our site, as appropriate, you may be asked to enter your:
                    name,
                    e-mail address, address or phone number. Neither credit card nor any bank account information is not stored by
                    us on our
                    servers.
                    When registering a school on our site, as appropriate, you may be asked to enter your: institution name, school
                    board,
                    address, and IP address.
                </p>
                <h4>HOW WE USE YOUR INFORMATION</h4>
                <p>QWACK EDUCATION may use your personal information to tailor the content programming, contests, services, and
                    applications that we provide to meet your needs and interests.</p>
                <ul>
                    <li>• To personalize your experience based on your website usage, progress, or preferences (your information
                        helps us to
                        better respond to your individual needs)</li>
                    <li>
                        • To improve our website (we continually strive to improve our website offerings based on the information
                        and feedback
                        we receive from you)
                    </li>
                    <li>
                        • To organize and administer a contest, promotion, survey or other site feature
                    </li>
                    <li>• To contact you. The email address you provide may be used to send you information, respond to inquiries,
                        and/or other
                        requests or questions. If you provide a phone number, this may be used to contact you about urgent matters
                        such as
                        contest day help and unpaid invoices.</li>

                </ul>
                <p>The anonymized information we track with cookies is not used to identify, or lead us back to, any particular
                    individual.
                    Visitors to this Website may have the option of disabling cookies via their browser preferences (most browsers
                    are
                    initially set up to accept cookies). Cookies make our Website easier for you to use because they save your
                    preferences
                    while you are visiting our sites.</p>
                <p>QWACK EDUCATION may also use third party vendors who supply applications, web hosting and other technologies for
                    this
                    Website that may have access to your personal information and anonymized information but by mutual agreement
                    will never
                    use such information for any other purpose except to provide services in connection with this site</p>
                <p>QWACK EDUCATION will not share any personal information about you with any third party, except as otherwise
                    stated in
                    this Privacy Statement and in the following circumstances: (a) in response to subpoenas, court orders or legal
                    process,
                    to the extent permitted and as restricted by law; (b) when disclosure is required to maintain the security and
                    integrity
                    of this site, or to protect any user's security or the security of other persons, consistent with applicable
                    laws; (c)
                    when disclosure is directed or consented to by the registered member who has inputted the personal information;
                    (d) in
                    the event that QWACK EDUCATION or any of its subdivisions or units goes through a business transition, such as a
                    merger,
                    divestiture, acquisition, liquidation or sale of all or a portion of its assets, your personal information will,
                    in most
                    instances, be part of the assets transferred; or (e) in limited circumstances, QWACK EDUCATION may disclose your
                    email
                    address in order to comply with laws and regulations.</p>
                <h5>CHILDREN'S PRIVACYCHILDREN'S PRIVACY</h5>
                <p>QWACK EDUCATION encourages parents and guardians to supervise their child's use of the Internet. We urge parents
                    to
                    instruct their children to never give out their real names, addresses, phone numbers, or other personal
                    information
                    without a parent's or guardian's permission when using the Internet. We recognize a special obligation to
                    protect
                    personal information obtained from children and by design do not collect this information. For this reason,
                    QWACK
                    EDUCATION does not request, collect, or store any personal information knowingly from children under 18. Some
                    games
                    might ask children for their names, but this information is never shared with anyone. We strive to keep the
                    level of
                    information collected from your child to a minimum, and in no event do we collect more information than what is
                    required
                    to use our learning app. The website contains no external links to third-party websites or services.

                    As a parent, you've probably done a lot of thinking about protecting your family's privacy on the Internet. We
                    have been
                    thinking and working hard to provide you with the best information and the highest quality interaction on the
                    Internet
                    in an environment that is safe. In compliance with COPPA (the Children's Online Privacy Protection Act), we have
                    adopted
                    the following policies to keep kids safe (there are exceptions to these policies as noted below under the
                    heading
                    “Exceptions to Verifiable Parental Consent and Information Collected from Children”):
                </p>
                <ul>
                    <li>• We will NOT knowingly collect, use, or distribute personal information from children under the age of 18
                        without prior
                        verifiable consent from a parent or guardian</li>
                    <li>• We will NOT knowingly ask a child under the age of 18 to divulge more information than is needed to
                        participate in any
                        activity, game, or service</li>
                    <li>• We will NOT knowingly keep any personal information that is inadvertently included in a child's email
                        submission, help
                        inquiry, or other one-time request, if we know that the child is under the age of 18. Any such information
                        will be
                        purged promptly after the issue has been resolved.</li>
                    <li>• We will NOT knowingly post any personal information that is inadvertently included in a child's submission
                        to a
                        general audience contribution area if we know that the child is under the age of 18. Upon notice to us,
                        these
                        submissions will be deleted and handled anonymously thereafter</li>
                    <li>• We will NOT knowingly provide any direct link to websites that are unsuitable for children or teens.</li>
                    <li>• Upon request, QWACK EDUCATION will provide a parent or guardian with a description of the information that
                        may have
                        been provided about their child during a registration process for a program or contest or membership plans
                        of this site.
                        QWACK EDUCATION only asks for a child's or teen's personal information if it is necessary to participate in
                        an activity,
                        game or contest. If we have collected and stored personal information from a child, we will give parents the
                        ability to
                        review their child's personal information, request that we discontinue further collection or use of their
                        child's
                        personal information, and/or request that we delete their child's personal information</li>

                </ul>
                <p>If you have any questions about this Privacy Statement, please contact QWACK EDUCATION at info@qwackedu.com.</p>
                <h6>NOTIFICATION AND VERIFIABLE PARENTAL CONSENT</h6>
                <p>Before any personal information is collected from a child, we will do our best to notify the parent or guardian
                    that
                    their child is participating. Notification will include the exact nature and intended use of the information we
                    are
                    collecting, as well as the opportunity for the parent or guardian to prevent the use of the information and the
                    child's
                    participation in the activity. For every child (anyone under 18 years of age), we will also require the parent
                    or
                    guardian to provide verifiable parental consent form.
                    In order for us to provide notice to a parent or guardian and seek verifiable parental consent, we may request
                    the
                    online contact information of a parent or guardian. This contact information will only be used for the sole
                    purpose
                    of
                    obtaining verifiable parental consent or providing notification to the parent or guardian.
                </p>
                <h6>Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)</h6>
                <p>QWACK EDUCATION may process your Personal Data because:</p>
                <ul>
                    <li>• We need to perform a contract with you</li>
                    <li>• You have given us permission to do so</li>
                    <li>• The processing is in our legitimate interests and it's not overridden by your rights</li>
                    <li>• To comply with the law</li>
                </ul>
                <h6>Retention of Data</h6>
                <p>QWACK EDUCATION will retain your Personal Data only for as long as is necessary for the purposes set out in this
                    Privacy
                    Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations
                    (for
                    example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce
                    our legal
                    agreements and policies.</p>
                <p>QWACK EDUCATION will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for
                    a
                    shorter period of time, except when this data is used to strengthen the security or to improve the functionality
                    of our
                    Service, or we are legally obligated to retain this data for longer time periods.</p>
                <h6>Disclosure Of Data</h6>
                <h6>Disclosure for Law Enforcement</h6>
                <p>Under certain circumstances, QWACK EDUCATION may be required to disclose your Personal Data if required to do so
                    by law
                    or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <h6>Legal Requirements</h6>
                <p>QWACK EDUCATION may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                    <li>• To comply with a legal obligation</li>
                    <li>• To protect and defend the rights or property of QWACK EDUCATION</li>
                    <li>• To prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>• To protect the personal safety of users of the Service or the public</li>
                    <li>• To protect against legal liability</li>
                </ul>
                <h6>Security Of Data</h6>
                <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or
                    method
                    of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your
                    Personal
                    Data, we cannot guarantee its absolute security.</p>
                <h6>Service Providers</h6>
                <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the
                    Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to
                    disclose or use it for any other purpose.</p>
                <h6>Analytics and External Services</h6>
                <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
                <h6>Google Analytics</h6>
                <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the
                    data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may
                    use the collected data to contextualize and personalize the ads of its own advertising network.</p>
                <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:
                    http://www.google.com/intl/en/policies/privacy/</p>
                <h6>Changes To This Privacy Policy</h6>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy
                    on this page.
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
                    when they are posted on this page.
                </p></div>
        </>
    )
}
