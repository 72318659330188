import React from 'react'
import "./SingleScore.scss"
import Confetti from 'react-confetti'
import useWindowDimensions from '../../Hooks/useWindowDimensions'
// import IconTwo from '../../assets/images/icon/2.png';
import { Link } from 'react-router-dom'
import useApi from '../../Hooks/useApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function SingleScore({ setMagicBar, setMagicNumber}) {
  setMagicBar(true);
setMagicNumber(2)

  const { width, height } = useWindowDimensions()
  const { tag } = useParams();
  const queryParams = new URLSearchParams(atob(tag))
  const s = queryParams.get("score")
  // const s = 10
  const t = queryParams.get("t")
  const l = queryParams.get("lvl")
  const n = queryParams.get("num")
  const d = queryParams.get("difficulty")
  const topic = queryParams.get("topid")
  const level = queryParams.get("lvlid")
  const { loading, data } = useApi(`${process.env.REACT_APP_SERVER}/api/topics/${topic}`)
  const top = loading ? [] : data.data
  const attributes = loading ? {} : top.attributes
  const levels = loading ? [] : attributes.levels
  const next = loading ? -1 : levels.indexOf(parseInt(level)) + 1
  const nextLevel = levels[next]
  const [nextPath, setNextPath] = useState(true)
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
  if(next >= levels.length) {
    setNextPath(false)
  } else {
    setNextPath(true)
  }
  }, [next])
  return (
    <>
    { !loading ? (
      <>
      { s >= t*80/100 &&
      <Confetti
      width={width}
      height={height}
      />
    }
      <div className="singscore">
        <div className="box">
          <div className="single-box">
            <div className="img">
              <i class={`fa fa-star ${ s >= t*30/100 && 'true'}`} ></i>
              <i class={`fa fa-star ${ s >= t*60/100 && 'true'}`}></i>
              <i class={`fa fa-star ${ s === t && 'true'}`}></i>
            </div>
            <p>You Scored</p>
            <h2>{s} marks out of {t}</h2>
            <p>in {l}</p>
            <p>Difficulty : {capitalizeFirstLetter(d)} Level : {n}</p>
            <Link to="/quiz">Home</Link>
            <Link to="/quiz/topics?src=single">Play again</Link>
            {nextPath && (
            <Link to={`/quiz/playing?lvlid=${nextLevel}&src=single&topid=${topic}`}>Next</Link>
            )}
          </div>

        </div>
      </div>

    </>
    ) : null}
    
    </>

  )
}
