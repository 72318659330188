import React from 'react'
import "./MagicBar.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faTrophy, faA, faAlignJustify, faBrain } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
export default function MagicBar({
  magicBar,
  magicNumber
}) {
  return (
    <div className={`MagicBar ${!magicBar && "hidden"}`}>
      <div className="navigation">
        <ul>
          <li className= {`list ${magicNumber === 0 && "active"}`}>
            <Link to="/">
              <span className="icon">
                <FontAwesomeIcon icon={faHome} />
              </span>
              <span className="texta">Home</span>
            </Link>
          </li>
          <li className={`list ${magicNumber === 1 && "active"}`}>
            <Link to="/worksheets">
              <span className="icon">
                <FontAwesomeIcon icon={faAlignJustify} />
              </span>
              <span className="texta">Worksheet</span>
            </Link>
          </li>
          <li className={`list ${magicNumber === 2 && "active"}`}>
            <Link to="quiz">
              <span className="icon">
                <FontAwesomeIcon icon={faBrain} />
              </span>
              <span className="texta">Quizzes</span>
            </Link>
          </li>
          <li className={`list ${magicNumber === 3 && "active"}`}>
            <Link to="/vocabulary">
              <span className="icon">
                <FontAwesomeIcon icon={faA} />
              </span>
              <span className="texta">Vocabulary</span>
            </Link>
          </li>
          <li className={`list ${magicNumber === 4 && "active"}`}>
            <Link to="/competition">
              <span className="icon ">
                <FontAwesomeIcon icon={faTrophy} />
              </span>
              <span className="texta">Competition</span>
            </Link>
          </li>
          <div className="indicator">
          </div>
        </ul>
      </div>
    </div>
  )
}
