import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes as Switch, Navigate } from 'react-router-dom';
import Error from './components/Error';
import Member from './components/Member';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import Home from './components/Home';
import Competition from './components/Competition';
import Vocabulary from './components/Vocabulary';
import WordOftheDay from './components/WordOftheDay';
import Playing from './components/Playing';
import Topics from './components/Topics'
import ScoreBoard from './components/ScoreBoard'
import Single from './components/SingleScore'
import Worksheets from './components/Worksheets';
import WorksheetSingle from './components/WorksheetSingle';
import VocabGames from './components/VocabGames';
import VocabScore from './components/VocabScore';
import WordCard from './components/WordCard';
import Dropit from './components/Dropit';
import DropitSingle from './components/DropitSingle';
import Quiz from './components/Quiz';
import Register from './components/Register';
import CompPaper from './components/CompPaper';
import CompPapers from './components/CompPapers';
import CompPlaying from './components/CompPlaying';
import Waiting from './components/Waiting';
import Participate from './components/Participate';
import Completed from './components/Completed';
import About from './components/About';
import Privacy from './components/Privacy';
import Tnc from './components/TnC'
import Ehard from './components/Ehard';
import Signup from './components/Signup';
import Login from './components/Login';
import Loginp from './components/Loginp';
import Otp from './components/Otp';
import playback from './assets/audio/vocabdropit.mp3';
import useSound from "use-sound";
import Intro from './components/Intro';
import MagicBar from './components/MagicBar';
import './launch.scss';

function Routes() {
    const [loop, { stop }] = useSound(playback, { volume: 0.05, loop: true });
    const [user, setUser] = useState(null);
    const [magicBar, setMagicBar] = useState(true);
    const [magicNumber, setMagicNumber] = useState(2);

    const introed = localStorage.getItem('introed');
    useEffect(() => {
        // const user = localStorage.getItem('user');
        // if (user) {
        //     setUser(JSON.parse(user));
        // }
        // location.reload();

        // setLoading(false);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
        window.localStorage.getItem('childsName') && setUser(JSON.parse(window.localStorage.getItem('childsName')));
    }, []);

    const [players, setPlayers] = useState([{
        'id': 1,
        'name': "Darshan",
        'score': 80
    },
    {
        'id': 2,
        'name': "Reedham",
        'score': 900
    },
    {
        'id': 3,
        'name': "Janki",
        'score': 900
    },
    {
        'id': 4,
        'name': "Shiva",
        'score': 70
    }])
    // stop()
    return (
        <>


            <div className={`visible active`}>
                <Router>
                    <MagicBar magicBar={magicBar} magicNumber={magicNumber}></MagicBar>
                    <ScrollToTop>
                        <Switch>
                            {
                                user ? (
                                    <>
                                        <Route path="/" element={<Home user={user} setUser={setUser} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/worksheets" element={<Worksheets user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/worksheets/:name" element={<WorksheetSingle user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/vocabulary" element={<Vocabulary user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/vocabulary/word" element={<WordOftheDay user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/vocabulary/games" element={<VocabGames user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/vocabulary/games/ehard" element={<Ehard user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/vocabulary/games/wordcard" element={<WordCard user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/vocabulary/games/wordcard/score" element={<VocabScore user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/vocabulary/games/dropit" element={<Dropit user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/vocabulary/games/dropit/playing" element={<DropitSingle setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/quiz" element={<Competition user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                //     {/* <Route path="/competition/join" element={<Join players={players} setPlayers= {setPlayers}/>} /> 
                                // <Route path="/competition/create" element={<Create players={players} setPlayers= {setPlayers}/>} />  */}
                                        <Route path="/quiz/topics" element={<Topics players={players} setPlayers={setPlayers} user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/quiz/playing" element={<Playing players={players} setPlayers={setPlayers} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                // {/* <Route path="/quiz/scores" element={<ScoreBoard players={players} setPlayers={setPlayers} />} /> */}
                                        <Route path="/quiz/score-single/:tag" element={<Single players={players} setPlayers={setPlayers} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                // {/* <Route path="/quiz/waiting" element={<Waitingroom players={players} setPlayers= {setPlayers}/>} /> */}
                                        <Route path="/competition" element={<Quiz user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/competition/register" element={<Register setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/competition/papers" element={<CompPapers user={user} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/competition/papers/:tag" element={<Navigate to="../competition/papers" replace setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/competition/papers/:tag/:id" element={<CompPaper setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/competition/waiting" element={<Waiting setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/competition/participate" element={<Participate setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/competition/playing" element={<CompPlaying setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/competition/completed" element={<Completed setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path='/AboutUs' element={<About user={user} setUser={setUser} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path='/refund' element={<About user={user} setUser={setUser} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/member" element={<Signup user={user} setUser={setUser} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/signup" element={<Signup user={user} setUser={setUser} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/otp" element={<Otp setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="/error" element={<Error setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        <Route path="*" element={<Error setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                    </>
                                )
                                    : (
                                        <>
                                            <Route path='/T&C' element={<Tnc user={user} setUser={setUser} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                            <Route path='/privacy' element={<Privacy user={user} setUser={setUser} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                            <Route path="/login" element={<Login setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                            <Route path="/loginp" element={<Loginp setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                            {
                                                !introed &&
                                                <Route path="/" element={<Intro setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                            }
                                            <Route path="*" element={<Signup user={user} setUser={setUser} setMagicBar={setMagicBar} setMagicNumber={setMagicNumber} />} />
                                        </>
                                    )
                            }
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>

        </>
    );
}

export default Routes;