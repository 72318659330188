import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './waiting.scss';
import CompWait from '../Helper/compwait';

export default function Waiting({setMagicBar, setMagicNumber}) {
    setMagicBar(false);
setMagicNumber(4)
    const navigate = useNavigate();
    // rules
//     1.The examination will comprise of Objective type Multiple Choice Questions (MCQs) 
// 2. All questions are compulsory and each carries One mark. 
// 3. Total there are 30 questions, for each question student will get 40 seconds. 
// 4. The Subjects or topics covered in the exam will be as per the Syllabus.
// 5. There will be NO NEGATIVE MARKING for the wrong answers.
// 6.You have to compulsory wait for the next question.
// 7. Rank will be decided on the basis of correct answers given in the least amount of time
// 8. Time will be noted when the participant clicks on the answer
// 9. If you will not attend the question your time will be considered 40 seconds
    
    const wait = CompWait();
    useEffect(() => {
        // get compUser from local storage
        const compUser = JSON.parse(localStorage.getItem('comp4User'));
        if (!compUser) {
            // alert("You are not registered for the competition");
            navigate('/competition/participate');
        }
        checkWait();
    }, [wait]);
            

    const checkWait = () => {
        if (wait > 600) {
            navigate('/competition');
             alert("The competition will begin shortly, please wait");
        } else
         if (wait < 0) {
            navigate('/competition/playing');
        } else {
           return;
        }
    }
    // function to convert seconds to minutes and seconds
    const convertToTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}m ${secs}s`;
    };

    // useEffect(() => {
    //     alert(`You will be redirected in ${convertToTime(wait)}, Do not close this window, or press back button. Read all rules carefully.`);
    // }, []);
    const time = convertToTime(wait);
    return (
        <div className='waiting'>
            <div className="timer-wrapper">
                <h3>Competition will begin in:</h3>
                <h1>{time}</h1>
            </div>
            <div className="rules-wrapper">
                <h1>Rules</h1>
                <ul>
                    <li>1.The examination will comprise of Objective type Multiple Choice Questions (MCQs)</li>
                    <li>2.All questions are compulsory and each carries One mark.</li>
                    <li>3.Total there are 30 questions, for each question student will get 40 seconds.</li>
                    <li>4.The Subjects or topics covered in the exam will be as per the Syllabus.</li>
                    <li>5.There will be NO NEGATIVE MARKING for the wrong answers.</li>
                    <li>6.You have to compulsory wait for the next question.</li>
                    <li>7.Rank will be decided on the basis of correct answers given in the least amount of time</li>
                    <li>8.Time will be noted when the participant clicks on the answer</li>
                    <li>9.If you will not attend the question your time will be considered 40 seconds</li>
                    
                </ul>
            </div>
        </div>
    )
}
