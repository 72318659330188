import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './Topics.scss'
import useApi from '../../Hooks/useApi'
import Drawer from '../Mobile/Drawer';
import Header from '../Header'
import useToggle from '../../Hooks/useToogle';
// import Footer from '../Footer';
import Loader from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalVideo from 'react-modal-video'
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';

export default function Topics({ user, setMagicBar, setMagicNumber }) {
    setMagicBar(true);
    setMagicNumber(2)
    
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")
    const src = queryParams.get("src")
    const category = queryParams.get("category")
    const { loading, data } = useApi(`${process.env.REACT_APP_SERVER}/api/topics${category ? `?filters[$and][0][category][$eq]=${category }` : '?filters[$and][0][category][$eq]=basic'}`)
    const temp = loading ? [] : data.data
    const topics = temp.sort((a, b) => (a.attributes.order > b.attributes.order) ? 1 : -1)
    const [drawer, drawerAction] = useToggle(false);
    const [isOpen, setOpen] = useState(false)
    const role = localStorage.getItem('role')
    return (
        <>
            {!loading ?
                (
                    <>
                        <Drawer drawer={drawer} action={drawerAction.toggle} />
                        <Header action={drawerAction.toggle} user={user} />
                        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="M3cFPJCdw68" onClose={() => setOpen(false)} />
                        <div className='topic'>
                            <div className='head'>
                                <h1>Select a level</h1>
                                <p className='link'>
                                    <Link to="#" onClick={()=> setOpen(true)}>

                                    <FontAwesomeIcon icon={faCirclePlay} /> How to play?
                                    </Link>
                                    </p>
                                <p>Start playing by selecting a topic and choose a level to play</p>
                                <div className="starss">
                                    <p><div className='easy'></div> - Easy level -Suitable for Age : 7 to 8 years</p>
                                    <p><div className='medium'></div> - Medium level - Suitable for Age : 9 to 10 years</p>

                                </div>
                            </div>
                            <div class="topic-wrapper">
                                {topics.map((topic, ij) => (
                                    <div className="card" key={topic.id}>
                                        {ij === 0 && (
                                            <div className="Free">
                                                <span>Free</span>
                                            </div>
                                        )}

                                        <input type="checkbox" id={topic.id} class="more" aria-hidden="true" />
                                        <div className="content">
                                            <div className="front">
                                                <div className="inner">
                                                    <h3>{topic.attributes.topic}</h3>
                                                    <div className="rating">
                                                        {topic.attributes.levels.map((e, i) =>
                                                        (<i
                                                            className={`fas fa-star ${i + 1 > topic.attributes.easy ? 'medium' : ''}`}
                                                            key={i}
                                                        >
                                                        </i>)
                                                        )}

                                                    </div>
                                                    <label for={topic.id} className="button" aria-hidden="true">
                                                        Play Now
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="back">
                                                <div className="inner">
                                                    <div className='cover'>
                                                        {topic.attributes.easy &&
                                                            <div className='wrapper-11'>
                                                                <h3>Easy</h3>
                                                                <div>

                                                                    {topic.attributes.levels.map((e, i) =>
                                                                    (<div className={`info ${i + 1 > topic.attributes.easy ? 'hide' : ''}`}>
                                                                        <Link to={user && role === "\"member\"" || ij === 0 ? `${src === "single" ? `/quiz/playing?lvlid=${e}&src=single&topid=${topic.id}` : `/quiz/waiting?code=${code}&lvlid=${e}&topid=${topic.id}`}` : '/login'}>
                                                                            <label className={`button return ${i + 1 > topic.attributes.easy ? 'medium' : ''}`} aria-hidden="true">
                                                                                {user && role === "\"member\"" || ij === 0 ? (
                                                                                    i + 1
                                                                                ) : (
                                                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                                                )}
                                                                            </label>
                                                                        </Link>
                                                                    </div>)
                                                                    )}
                                                                </div>
                                                            </div>
                                                        }
                                                        {topic.attributes.hard &&
                                                            <div>

                                                                <h3>Hard</h3>
                                                                <div>

                                                                    {topic.attributes.levels.map((e, i) =>

                                                                    (<div className="info">
                                                                        <Link to={user && role === "\"member\"" || ij === 0 ? `${src === "single" ? `/quiz/playing?lvlid=${e}&src=single&topid=${topic.id}` : `/quiz/waiting?code=${code}&lvlid=${e}&topid=${topic.id}`}` : user ? '/member' : '/login' }>
                                                                            <label className={`button return ${i + 1 > topic.attributes.easy ? 'medium' : 'hide'}`} aria-hidden="true">
                                                                                {user && role === "\"member\"" || ij === 0 ? (

                                                                                    i + 1
                                                                                ) : (
                                                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                                                )}
                                                                            </label>
                                                                        </Link>
                                                                    </div>)
                                                                    )}
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* <div className="info">
                                        <Link to={`/quiz/waiting?code=${code}&lvlid=${''}`}>
                                            <label className="button return" aria-hidden="true">
                                                1
                                            </label>
                                        </Link>
                                    </div>
                                    <div class="info">
                                        <Link to='/quiz/playing'>
                                            <label class="button return" aria-hidden="true">
                                                2
                                            </label>
                                        </Link>
                                    </div>
                                    <div class="info">
                                        <Link to='/quiz/playing'>
                                            <label class="button return medium" aria-hidden="true">
                                                3
                                            </label>
                                        </Link>
                                    </div> */}
                                                    </div>
                                                    <label for={topic.id} class="button return" aria-hidden="true">
                                                        <i class="fas fa-arrow-left"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <Footer/> */}
                    </>
                ) : (

                    <Loader />
                )
            }
        </>

    )
}
