import React from 'react';
import Svg from './Svg';
import { useNavigate } from 'react-router-dom';


function Error({ setMagicBar, setMagicNumber}) {
    setMagicBar(false);
setMagicNumber(4)
    const navigate = useNavigate();
    return (
        <>
            <div className="error-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="error-content text-center">
                                <Svg />
                                <span>Sorry!</span>
                                <h3 className="title">The page can’t be found.</h3>
                                <p>
                                    The page you're looking for isn't available. Use the go back
                                    button below
                                </p>
                                <a onClick={() => navigate(-1)} href="#" aria-hidden='true'>
                                    Go Back <i className="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Error;
