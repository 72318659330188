import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function Footer({ className }) {
    return (
        <>
            <section className={`footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>
                                    Let's Practice Grammar
                                </p>
                                <a href="AboutUs">
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/QWACK20">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Quick links</h4>
                                <ul>
                                    <li>
                                        <Link to="/AboutUs">About Us</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/Service">Service</Link>
                                    </li> */}
                                    {/* <li>
                                        <a href="#">Case Studies</a>
                                    </li> */}
                                    {/* <li>
                                        <Link to="/news">Blog</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/T&C">Terms and Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Support</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">Community</Link>
                                    </li>
                                    <li>
                                        <a href="#">Resources</a>
                                    </li>
                                    
                                    <li>
                                        <a href="#">Careers</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:contact@qwackedu">
                                            <i className="fal fa-envelope" /> contact@qwackedu.com
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#">
                                            <i className="fas fa-phone" /> +(91) 7016664182
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" /> SF - 204 Aayna Complex <br/> Nr. Zydus Hospital, Habatpur Road <br/>Thaltej - Ahmedabad Gujarat - 380054
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                {/* <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-apple" /> Download for iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="copyright-text">
                                    <p>Copyright © 2022 Q.W.A.C.K. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;