import React, {useState} from 'react'
import Footer from '../Footer'
import Header from '../Header'
import './Worksheet.scss'
import Drawer from '../Mobile/Drawer';
import useToggle from '../../Hooks/useToogle';
import useApi from '../../Hooks/useApi'
import { Link } from 'react-router-dom'
import Loader from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalVideo from 'react-modal-video'
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';

export default function Worksheets({ 
    user,
    setMagicBar,
    setMagicNumber
}) {
    const link = `${process.env.REACT_APP_SERVER}/api/worksheets?filters[$and][0][hasBasic][$eq]=true`
    setMagicBar(true);
    setMagicNumber(1);
    const { loading, data } = useApi(link)
    const topics = loading ? [] : data.data;
    const [drawer, drawerAction] = useToggle(false);
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            {
                loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className='wrapme'>
                            <Drawer drawer={drawer} action={drawerAction.toggle} />
                            <Header action={drawerAction.toggle} user={user} />
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="NrPGVMWpDBM" onClose={() => setOpen(false)} />
                            <div className='head'>
                                <h1>Grammar Worksheets</h1>
                                <p>Click on any Topic and Use these printable Grammar concept sheets and Practice Worksheets to study basics English Grammar.</p>
                                <p className='link'>
                                    <Link to="#" onClick={()=> setOpen(true)}>

                                        <FontAwesomeIcon icon={faCirclePlay} /> How to Use?
                                    </Link>
                                </p>
                                <div className="line"></div>
                            </div>
                        </div>
                        <section id="info">
                            <div className="work-row">
                                {topics.map((topic, i) => (
                                    <div className="worksheet-card" key={i}>
                                        {i === 0 && (
                                            <div className="Free">
                                                <span>Free</span>
                                            </div>
                                        )}
                                        <Link to={`/worksheets/${topic.attributes.topic}`}>
                                            <h2>{topic.attributes.topic}</h2>
                                            <p>{topic.attributes.Description}</p></Link>
                                    </div>
                                ))}
                            </div>
                        </section>
                        <Footer />
                    </>
                )
            }

        </>
    )
}
