import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCU3Oe1ogTJxB6escwmDSsl1EkY8VmPL5s",
    authDomain: "qwack-education.firebaseapp.com",
    projectId: "qwack-education",
    storageBucket: "qwack-education.appspot.com",
    messagingSenderId: "62111072009",
    appId: "1:62111072009:web:920c3b1272d917a36b5dd8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;