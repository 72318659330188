import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import CompWait from '../Helper/compwait'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loading';
import useApi from '../../Hooks/useApi'
import axios from 'axios';
import { useParams } from 'react-router-dom';
export default function Playing({setMagicBar, setMagicNumber}) {
    const navigate = useNavigate();
    setMagicBar(true);
setMagicNumber(4)
    // get query params
    const urlParams = new URLSearchParams(window.location.search);
    const { id } = useParams();
    console.log(id);
    // const standard = urlParams.get('standard');
    const { data, error, loading } = useApi(`${process.env.REACT_APP_SERVER}/api/comp-questions/${id}`);
    const questions = loading ? [] : data.data.attributes.questions;
    // console.log(CompWait());
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const answer = ['a', 'b', 'c', 'd'];
    const OnNext = () => {
        if (currentQuestion < questions.length) {
            setCurrentQuestion(currentQuestion + 1);
        }
    }
    const OnPrev = () => {
        if (currentQuestion > 1) {
            setCurrentQuestion(currentQuestion - 1);
        }
    }
     
    return (
        <>
            {
                loading ? (
                    <Loader />
                ) : (

                    <div className='CompPlaying'>


                        <div className="cont">
                            <div className="head">

                                <div className={`option`}>
                                    <h3>Choose the correct Option</h3>
                                </div>
                            </div>
                            <div className="question-wrapper" style={{ transform: `translateX(-${(currentQuestion - 1) * 100}vw)` }}>
                                {questions.map((question, index) => (
                                    <div className="box">
                                        <div className="question" key={index}>
                                            <div className="number">{index + 1}</div>
                                            <h2>{question.question}</h2>
                                        </div>
                                        <div className="answers">
                                            {question.options.map((option, index1) => (
                                                <div className={`option`} key={index1} >
                                                    <div className="abcd">{answer[index1]}.</div>
                                                    <h3>{option}</h3>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                                )}
                            </div>
                            <div className="head clown">
                                {
                                    currentQuestion === 1 || currentQuestion === questions.length  ? (
                                        currentQuestion === 1 ? (
                                            <div className={`small`} onClick={() => {OnNext()}}>
                                            <h3>next</h3>
                                        </div>
                                        ) : (
                                            <div className={`small`} onClick={() => {OnPrev()}}>
                                            <h3>prev</h3>
                                        </div>
                                        )
                                    ) : (
                                    <>
                                        <div className={`small`} onClick={() => {OnNext()}}>
                                            <h3>next</h3>
                                        </div>
                                        <div className={`small`} onClick={() => {OnPrev()}}>
                                            <h3>prev</h3>
                                        </div>
                                    </>
                )
                        }
                            </div>
                        </div>

                    </div>
                )
            }
        </>
    )
}
