import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import Drawer from '../Mobile/Drawer';
import useToggle from '../../Hooks/useToogle';
import Header from '../Header';
import BackToTop from '../Helper/BackToTop';
import Footer from '../Footer';
import winner from '../../assets/images/quiz/winners-1.png';
import winner2 from '../../assets/images/quiz/winners-2.png';
import winner3 from '../../assets/images/quiz/winners-3.png';
import { Link } from 'react-router-dom'
import './Quiz.scss'
import { useNavigate } from 'react-router-dom';
import CompWait from '../Helper/compwait';

export default function Quiz({ user, setMagicBar, setMagicNumber }) {
  setMagicBar(true);
setMagicNumber(4)

  const navigate = useNavigate();
  const wait = CompWait();
  const [drawer, drawerAction] = useToggle(false);
  const [compUser, setCompUser] = useState();
  const [message, setMessage] = useState('');
  const route = () => {
    if (wait >= 7200) {
      if(!compUser) {
        navigate('./register')
      } else {
        alert("You have already registered for the competition");
      }
    } else if (wait >= 600 && wait <= 7200) {
      alert("Please wait for the competition to start, Registrations are closed");
    } else if (wait < 600 && wait >= -1200) {
      navigate('./waiting');
    } else {
      alert("Competition has ended");
    }
  }
  useEffect(() => {
    // get user from local storage
    const user1 = JSON.parse(localStorage.getItem('comp4User'));
    setCompUser(user1);
    if (wait >= 7200) {
      if(!user1) {
        setMessage('Register now');
      } else {
        setMessage('Registered');
      }
    } else if (wait >= 600 && wait <= 7200) {
      if(!user1) {
        setMessage('Registeration Closed');
      } else {
        setMessage('Registered');
      }
    } else if (wait < 600 && wait >= -1200) {
      setMessage('Participate Now');
    } else {
      setMessage('Competition Ended');
    }
  }, []);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} user={user} />
      <div className="quiz">
        <div className="container">
          <div className="content">
            <div>
              <h2 className='main-head'>Online Grammar Competition</h2>
              <p> QWACK EDUCATION will organize a Grammar Competition for kids on regular intervals to boost their Grammar skills.</p>
              {/* <p>The competition will test age relevant English Grammar skills and leader board will be shown after completion of the Competition.</p> */}
            </div>
            <div className="dates">
              <div className="box">
                <div className="title-1">Date</div>
                <div className="details">
                  <h2>19<sup>th</sup></h2>
                  <p> Febuary, 2023 </p>
                  {/* <h2>Coming Soon</h2> */}
                </div>
              </div>
              <div className="box">
                <div className="title-1">Time</div>
                <div className="details">
                <h2>11<sup>am</sup></h2>
                  <p> 30 min test </p>
                  {/* <h2>Coming Soon</h2> */}
                </div>
              </div>
            </div>
            <div className="butts">

              <div className="button-6"
                onClick={() => route()}
              >
                {message ? message : 'Register Now'}
              </div>
              <Link to="./papers">
                <div className="button-6">
                  Question papers
                </div>
              </Link>
            </div>
          </div>

          <div className="image">
            <img src={winner} alt="placeholder" />
          </div>
        </div>
        <div className="container">
          <div className="content">
            <h2>How to participate?</h2>
            <p>Students can register by filling their Details on our website's Competition Registration form.
            </p>
          </div>
          <div className="image">
            <img src={winner2} alt="placeholder" />
          </div></div>
        <div className="container">
          <div className="content">
            <h2>Winner selection criteria</h2>
            <p style={{ color: "#fff" }}>Winners will be selected using the following criteria</p>
            <ul>
              <li>Numbers of correct answers.</li>
              <li>Time taken for answering each question of the competition.</li>
            </ul>
          </div>
          <div className="image">
            <img src={winner3} alt="placeholder" />
          </div></div>
      </div>
      {/* <Footer /> */}
      <BackToTop />
    </>
  )
}
