import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/css/bootstrap.min.scss';
import './assets/css/custom-animated.scss';
import './assets/css/default.scss';
import './assets/css/font-awesome.min.scss';
import './assets/css/magnific-popup.scss';
import './assets/css/magnific.rtl.scss';
import './assets/css/main.scss';
import { CookiesProvider } from "react-cookie";
import './assets/css/style.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { Provider } from 'react-redux';
// import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import reducers from './reducers';

// const store = createStore(reducers, compose(applyMiddleware(thunk)));
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    // <Provider store={store}>
    <CookiesProvider>
      <App tab="home" />
   </CookiesProvider>
    // </Provider>
);

serviceWorkerRegistration.register();
