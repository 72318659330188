import React from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import Snowfall from 'react-snowfall';
import useToggle from '../../Hooks/useToogle';
import Drawer from '../Mobile/Drawer';
import Header from '../Header';
import './competition.scss';
import Footer from '../Footer';
import IconTwo from '../../assets/images/icon/2.png';
import { useState } from 'react';
import { socket } from '../../config/web-sockets'
// import useWindowDimensions from '../../Hooks/useWindowDimensions';


export default function Competition({
     user , setMagicBar, setMagicNumber
    }) {
        setMagicBar(true);
setMagicNumber(2)

    // const { height, width } = useWindowDimensions();
    const [drawer, drawerAction] = useToggle(false);
    const [display, setDisplay] = useState(true);
    const [username, setUsername] = useState('');
    const [room, setRoom] = useState('');
    const [error, setError] = useState('');
    const onUsernameChange = (e) => {
        const inputValue = e.target.value;
        setUsername(inputValue);

    }
    const onRoomChange = (e) => {
        const roomNo = e.target.value;
        setRoom(roomNo);
    }
    const onClick = () => {
        if (username && room) {
           
            socket.emit('join', { username, room }, (error) => {
                if (error) {
                    setError(error)
                    alert(error);
                } else {
                    console.log('congratulations');
                }
            });
        }
    }
    // socket.on('welcome', (data) => {
    //     props.onJoinSuccess(data);
    // });

    return (
        <>
            <div class="compbackground">
                <div class="compcube"></div>
                <div class="compcube"></div>
                <div class="compcube"></div>
                <div class="compcube"></div>
                <div class="compcube"></div>
                <div class="compcube"></div>
                <Drawer drawer={drawer} action={drawerAction.toggle} />
                <Header action={drawerAction.toggle} user={user} />
                {/* <div class="bg">
                <svg className='svg-parent' xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`} height={height} width={width}>
                <g className='svg-child' xmlns="http://www.w3.org/2000/svg" transform={`translate(${width}, 0)`}>
                <path d="M0 351C-12.6 300.9 -25.2 250.8 -41.2 233.4C-57.1 216 -76.5 231.4 -78.3 215.2C-80.2 199 -64.6 151.2 -97.5 168.9C-130.4 186.6 -211.9 269.8 -225.6 268.9C-239.4 268 -185.4 183.1 -176.2 147.8C-167 112.6 -202.6 127.1 -212.2 122.5C-221.8 117.9 -205.4 94.3 -226.5 82.4C-247.5 70.6 -305.9 70.5 -332.9 58.7C-359.8 46.9 -355.4 23.5 -351 0L0 0Z" fill="#ffc003" />
                <path d="M0 175.5C-6.3 150.4 -12.6 125.4 -20.6 116.7C-28.6 108 -38.2 115.7 -39.2 107.6C-40.1 99.5 -32.3 75.6 -48.7 84.4C-65.2 93.3 -105.9 134.9 -112.8 134.4C-119.7 134 -92.7 91.5 -88.1 73.9C-83.5 56.3 -101.3 63.5 -106.1 61.3C-110.9 59 -102.7 47.1 -113.2 41.2C-123.7 35.3 -152.9 35.2 -166.4 29.3C-179.9 23.5 -177.7 11.7 -175.5 0L0 0Z" fill="#4316b1" />
                </g>
                </svg>
            </div> */}
                <div className='parent'>
                    {/* <Snowfall 
            color='#ffffff'
            snowflakeCount={300}
            radius = {[5,5]}
            style = {{opacity: 0.7}}
            /> */}
                    <div className='heading'>
                        <div>
                            <img className='trophy' src={IconTwo} alt="" />
                            <h1>Grammar Quizzes </h1>
                            <img className='trophy' src={IconTwo} alt="" />
                        </div>
                        {/* <p>
                        Students can Host and invite Friends to Play Grammar quiz by selecting any Grammar Topics.
                    </p> */}
                    </div>
                    <Link to={`/quiz/topics?src=single`}>
                    <div className='practice'>
                        <h2>Single Player</h2>
                        <p>(Practice Mode)</p>
                    </div>
                    </Link>
                    <div className='practice' onClick={()=> {
                        // setDisplay(!display)
                    }}
                    >
                        <h2>Play With Friends</h2>
                        <p>(Coming Soon) <i className='fa fa-info-circle' title='TODO: write instructions'></i> </p>
                    </div>
                    <div className="card-container" style={display ? {opacity:0} : {} }>
                        {/* <div class="card">
                            <div class="face face1">
                                <div class="content">
                                    <div class="main">
                                        <input type='text' className='input' placeholder='Enter your name' />
                                        <Link to={`/quiz/topics?code=${Math.floor(Math.random() * 10000)}`}>
                                            <button className='comp-button active'><span>Create</span></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="face face2">
                                <h2>Create a Room</h2>
                                <p>Students can Host and invite Friends to Play Grammar quiz by selecting any Grammar Topics.</p>
                            </div>
                        </div> */}

                        <div class="card">
                            <div class="face face1">
                                <div class="content">
                                    <div class="main">
                                        <input type='text' className='input' placeholder='Enter your name' onChange={onUsernameChange} />
                                        <input type='number' className='input' placeholder='Enter a code' onChange={onRoomChange} />
                                        {/* <Link to={`/quiz/waiting/`}> */}
                                        <button className='comp-button active' onClick={onClick}><span>Join</span></button>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                            <div class="face face2">
                                <h2>Join a Room</h2>
                                <p> Enter a code given by your friend.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    )
}
