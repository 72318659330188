import React, { useEffect, useState } from 'react';
import './Auth.scss';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import {
    RecaptchaVerifier,
    reload,
    signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from '../Helper/firebase';
export default function Auth({setMagicBar, setMagicNumber}) {
    setMagicBar(false);
setMagicNumber(0)
    const navigate = useNavigate();
    // form validation
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [username, setUsername] = useState('');
    const [childsName, setChildsName] = useState('');
    const [standard, setStandard] = useState('');
    const [password, setPassword] = useState('');
    const [passworda, setPassworda] = useState('');
    const [verified, setVerified] = useState(false);
    const [OTPdisalbed, setOTPdisalbed] = useState(false)
    const [password1, setPassword1] = useState(true)
    const [password2, setPassword2] = useState(true)
    const [isChecked, setIsChecked] = useState(true)


    const onSubmit = (e) => {
        if (!isChecked) {
            alert('please accept the privacy policy and terms of Use');
            return;
        }
        if (phone.length !== 10) {
            alert('please enter a valid phone number')
            return
        }
        axios.post(`${process.env.REACT_APP_SERVER}/api/user/send-otp`, { phone })
            .then(async (res) => {
                if (res.status === 200) {
                    alert(res.data.message);
                    //    alert('form submitted')
                } else {
                    alert(res.data.message)
                }
            })
    }
    const onSignUp = (e) => {
        if (!isChecked) {
            alert('please accept the privacy policy and terms of Use');
            return;
        }
        if (phone.length !== 10) {
            alert('please enter a valid phone number')
            setVerified(false)
            return
        }
        if (!username) {
            alert('please enter your name')
            return
        }
        if (!childsName) {
            alert('please enter your childs name')
            return
        }
        if (!standard) {
            alert('please enter your childs standard')
            return
        }
        if (!password) {
            alert('please enter a password')
            return
        }
        if (password.length < 6) {
            alert('password should be atleast 6 characters long')
            return
        }
        if (password !== passworda) {
            alert('passwords do not match')
            return
        }
        axios.post(`${process.env.REACT_APP_SERVER}/api/user/signup`, { username, childsName, phone, standard, password })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    alert(res.data.message);
                    console.log(res.data.childsName);
                    window.localStorage.setItem('phone', JSON.stringify(res.data.phone));
                    window.localStorage.setItem('id', JSON.stringify(res.data.id));
                    window.localStorage.setItem('username', JSON.stringify(res.data.username));
                    window.localStorage.setItem('childsName', JSON.stringify(res.data.childsName));
                    window.localStorage.setItem('standard', JSON.stringify(res.data.standard));
                    window.localStorage.setItem('role', JSON.stringify(res.data.role));
                    navigate('/member');
                    window.location.reload();
                } else {
                    alert(res.data.message)
                }
            }).catch((err) => {
                alert(err.response.data.message)
            })
    }
    const onVerify = (e) => {
        console.log(otp);
        axios.post(`${process.env.REACT_APP_SERVER}/api/user/verify-otp`, { otp, phone })
            .then(async (res) => {
                if (res.status === 200) {
                    setVerified(true)
                } else {
                    alert(res.data.message)
                }
            }).catch((err) => {
                console.log(err.response.data.message);
                alert(err.response.data.message)
            })
    }

    return (
        <>
            <div className="signup">
                <div className="signup-container">
                    <div className="signup-header">
                        <h1 onClick={() => {
                            // delete localStorage.introed
                            localStorage.removeItem('introed')
                            window.location.reload()
                        }}>Sign Up</h1>
                        <p>Sign up for free and get free benefits</p>
                    </div>
                    {!verified && <div className="signup-form">
                        {/* <input type="text" name="username" id="name1" placeholder='Enter your Name' value={form.username} onChange={(e) => onchange(e)} />
                        <input type="text" name="childsName" id="name2" placeholder="Enter your child's Name" onChange={(e) => onchange(e)} /> */}
                        {/* <label htmlFor="standard">Standard:</label> */}
                        {/* <select name="standard" id="standard" value={form.standard} onChange={(e) => onchange(e)}>
                            <option value="null">Select a standard</option>
                            <option value="a-2nd">2</option>
                            <option value="a-3rd">3</option>
                            <option value="a-4th">4</option>
                            <option value="a-5th">5</option>
                        </select> */}
                        <div className="phone">
                            <label htmlFor="Your Phone"><FontAwesomeIcon icon={faWhatsapp} />+91</label>
                            <input type="text" name="phone" id="phone1" placeholder='Enter your WhatsApp Number' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        {/* <button>Get OTP on Whatsapp <i className='fa fa-whatsapp'></i></button> */}
                        {/* <div className="password">
                            <input type={password1 ? "password" : "text"} name="password" id="password1" placeholder='Enter your Password (min. 6 characters)' onChange={(e) => onchange(e)} />
                            <i className={`fa ${!password1 ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setPassword1(!password1)}></i>
                        </div>
                        <div className="password">
                            <input type={password2 ? "password" : "text"} name="confirmPassword" id="password2" placeholder='Confirm your Password' onChange={(e) => onchange(e)} />
                            <i className={`fa ${!password2 ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setPassword2(!password2)}></i>
                        </div> */}
                        {/* <div id="recaptcha-container" style={{ display: sent && 'none' }}></div> */}
                        <button onClick={() => onSubmit()} className="btn-primary" disabled={OTPdisalbed}>Get OTP</button>
                        <input type="text" name="otp" id="otp" placeholder='Enter OTP' value={otp} onChange={(e) => setOtp(e.target.value)} />
                        <div>
                            <input type="checkbox" name="Privacy" id="privacy" checked={isChecked} onClick={() => { setIsChecked(!isChecked) }} readOnly />
                            <label htmlFor="Privacy" onClick={() => { setIsChecked(!isChecked) }} >I agree to the <Link to="/privacy">Privacy Policy</Link> and <Link to="/T&C">Terms of Use</Link></label>
                        </div>
                        <button onClick={(e) => onVerify(e)} className="btn-primary">Verify</button>
                        <div>
                            Already a member Log in <Link to="/login" >here</Link>
                        </div>
                    </div>}
                    {verified && <div className="signup-form">
                        <input type="text" name="username" id="name1" placeholder='Enter your Name' value={username} onChange={(e) => setUsername(e.target.value)} />
                        <input type="text" name="childsName" id="name2" placeholder="Enter your child's Name" value={childsName} onChange={(e) => setChildsName(e.target.value)} />
                        <label htmlFor="standard">Standard:</label>
                        <select name="standard" id="standard" value={standard} onChange={(e) => setStandard(e.target.value)}>
                            <option value="null">Select a standard</option>
                            <option value="a-2nd">2</option>
                            <option value="a-3rd">3</option>
                            <option value="a-4th">4</option>
                            <option value="a-5th">5</option>
                        </select>
                        {/* <div className="phone">
                            <label htmlFor="Your Phone"><FontAwesomeIcon icon={faWhatsapp} />+91</label>
                            <input type="text" name="phone" id="phone1" placeholder='Enter your WhatsApp Number' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div> */}
                        {/* <button>Get OTP on Whatsapp <i className='fa fa-whatsapp'></i></button> */}
                        <div className="password">
                            <input type={password1 ? "password" : "text"} name="password" id="password1" placeholder='Enter your Password (min. 6 characters)' value={password} onChange={(e) => setPassword(e.target.value)} />
                            <i className={`fa ${!password1 ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setPassword1(!password1)}></i>
                        </div>
                        <div className="password">
                            <input type={password2 ? "password" : "text"} name="confirmPassword" id="password2" placeholder='Confirm your Password' value={passworda} onChange={(e) => setPassworda(e.target.value)} />
                            <i className={`fa ${!password2 ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setPassword2(!password2)}></i>
                        </div>
                        {/* <div id="recaptcha-container" style={{ display: sent && 'none' }}></div> */}
                        {/* <button onClick={() => onSubmit()} className="btn-primary" disabled={OTPdisalbed}>Get OTP</button>
                        <input type="text" name="otp" id="otp" placeholder='Enter OTP' value={otp} onChange={(e) => setOtp(e.target.value)} /> */}
                        <div>
                            <input type="checkbox" name="Privacy" id="privacy" checked={isChecked} onClick={() => { setIsChecked(!isChecked) }} readOnly />
                            <label htmlFor="Privacy" onClick={() => { setIsChecked(!isChecked) }} >I agree to the <Link to="/privacy">Privacy Policy</Link> and <Link to="/T&C">Terms of Use</Link></label>
                        </div>
                        <button onClick={(e) => onSignUp(e)} className="btn-primary">Sign Up</button>
                        <div>
                            Already a member Log in <Link to="/login" >here</Link>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}
