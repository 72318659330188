import React from 'react';
import projectThumb from '../../assets/images/project-thumb.png';
import { Link } from 'react-router-dom';

function Project({ className }) {
    return (
        <>
            <section className={`project-area pb-100 ${className || ''}`}>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="project-box wow animated slideInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="project-content">
                                            <h3 className="title">
                                                Let's Practice Grammar.
                                            </h3>
                                            <p style={{opacity:1, marginTop:10}}>QWACK Education membership plan starts from <br /> 
                                            <p style={{fontSize:"1.5rem", textDecoration: "line-through"}}> ₹ 999/year</p>
                                            <b style={{fontSize:"2rem", marginTop:10}}>Launch Offer ₹399/year</b>
                                            </p>
                                                <div className="input-box mt-30">
                                                    {/* <input type="text" placeholder="Your Email" /> */}
                                                    {
                                                        window.localStorage.getItem("role") ? (
                                                            <Link to="/worksheets" className="main-btn">
                                                                Start Now
                                                            </Link>
                                                        ) : (   
                                                            <>
                                                            <Link to="/login" className="main-btn">
                                                                Login
                                                            </Link>
                                                    <Link to="/signup"><button type="button" > Become a Member</button></Link>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-thumb">
                                    <img src={projectThumb} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Project;
