import React from 'react';
import {Link} from 'react-router-dom';

function Navigation() {
    return (
        <>
    
                <ul>
                    <li>
                        <Link to="/">Home </Link>
                    </li>
                    <li>
                        <Link to="/worksheets"> Worksheets</Link>
                    </li>
                    <li>
                        <Link to="/vocabulary">Vocabulary </Link>
                    </li>
                    <li>
                        <Link to="/quiz"> Quiz </Link>
                    </li>
                    <li>
                        <Link to="/competition">Competition</Link>
                    </li>
                    <li>
                        <Link to="/AboutUs">About us</Link>
                    </li>
                </ul>
           
        </>
    );
}

export default Navigation;
