const anthard = [
    [
        [
            {
                "id": 3,
                "word": "BEAUTIFUL",
                "pairId": 3
            },
            {
                "id": 4,
                "word": "BRAVE",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "ADVANCE",
                "pairId": 2
            },
            {
                "id": 1,
                "word": "CONDEMN",
                "pairId": 1
            },
            {
                "id": 5,
                "word": "CONQUER",
                "pairId": 5
            }
        ],
        [
            {
                "id": 2,
                "word": "DEEP",
                "pairId": 2
            },
            {
                "id": 3,
                "word": "DISGRACE",
                "pairId": 3
            },
            {
                "id": 4,
                "word": "DWARF",
                "pairId": 4
            },
            {
                "id": 1,
                "word": "EXCITING",
                "pairId": 1
            },
            {
                "id": 5,
                "word": "EXHIBIT",
                "pairId": 5
            }
        ],
        [
            {
                "id": 3,
                "word": "FEMALE",
                "pairId": 3
            },
            {
                "id": 1,
                "word": "FLOOR",
                "pairId": 1
            },
            {
                "id": 4,
                "word": "FREEZE",
                "pairId": 4
            },
            {
                "id": 5,
                "word": "IDLE",
                "pairId": 5
            },
            {
                "id": 2,
                "word": "KNOWLEDGE",
                "pairId": 2
            }
        ],
        [
            {
                "id": 2,
                "word": "LENGTH",
                "pairId": 2
            },
            {
                "id": 4,
                "word": "MISERABLE",
                "pairId": 4
            },
            {
                "id": 1,
                "word": "MOIST",
                "pairId": 1
            },
            {
                "id": 3,
                "word": "SELDOM",
                "pairId": 3
            },
            {
                "id": 5,
                "word": "PERMIT",
                "pairId": 5
            }
        ],
        [
            {
                "id": 4,
                "word": "BRIGHT",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "POLITE",
                "pairId": 2
            },
            {
                "id": 5,
                "word": "PUPIL",
                "pairId": 5
            },
            {
                "id": 3,
                "word": "BUY",
                "pairId": 3
            },
            {
                "id": 1,
                "word": "RARE",
                "pairId": 1
            }
        ],
        [
            {
                "id": 4,
                "word": "REWARD",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "ROUGH",
                "pairId": 2
            },
            {
                "id": 5,
                "word": "SWIFT",
                "pairId": 5
            },
            {
                "id": 3,
                "word": "TAME",
                "pairId": 3
            },
            {
                "id": 1,
                "word": "WORTHLESS",
                "pairId": 1
            }
        ],
        [
            {
                "id": 4,
                "word": "ACCEPT",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "DISCOMFORT",
                "pairId": 2
            },
            {
                "id": 5,
                "word": "ANCIENT",
                "pairId": 5
            },
            {
                "id": 3,
                "word": "ASLEEP",
                "pairId": 3
            },
            {
                "id": 1,
                "word": "BARE",
                "pairId": 1
            }
        ],
        [
            {
                "id": 3,
                "word": "ARRIVE",
                "pairId": 3
            },
            {
                "id": 4,
                "word": "CRUEL",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "EVIL",
                "pairId": 2
            },
            {
                "id": 1,
                "word": "BRAVE",
                "pairId": 1
            },
            {
                "id": 5,
                "word": "DRY",
                "pairId": 5
            }
        ],
        [
            {
                "id": 3,
                "word": "BIG",
                "pairId": 3
            },
            {
                "id": 1,
                "word": "COOL",
                "pairId": 1
            },
            {
                "id": 4,
                "word": "FOOLISH",
                "pairId": 4
            },
            {
                "id": 5,
                "word": "BELIEF",
                "pairId": 5
            },
            {
                "id": 2,
                "word": "ALONE",
                "pairId": 2
            }
        ],
        [
            {
                "id": 4,
                "word": "AGREE",
                "pairId": 4
            },
            {
                "id": 2,
                "word": "FEW",
                "pairId": 2
            },
            {
                "id": 5,
                "word": "ARTIFICIAL",
                "pairId": 5
            },
            {
                "id": 3,
                "word": "FRESH",
                "pairId": 3
            },
            {
                "id": 1,
                "word": "FOLLOW",
                "pairId": 1
            }
        ]
    ],

    [
        {
            "1": {
                "name": "PRAISE",
                "items": []
            },
            "2": {
                "name": "RETREAT",
                "items": []
            },
            "3": {
                "name": "UGLY",
                "items": []
            },
            "4": {
                "name": "COWARDLY",
                "items": []
            },
            "5": {
                "name": "SURRENDER",
                "items": []
            }
        },
        {
            "1": {
                "name": "DULL",
                "items": []
            },
            "2": {
                "name": "SHALLOW",
                "items": []
            },
            "3": {
                "name": "HONOR",
                "items": []
            },
            "4": {
                "name": "GIANT",
                "items": []
            },
            "5": {
                "name": "HIDE",
                "items": []
            }
        },
        {
            "1": {
                "name": "CEILING",
                "items": []
            },
            "2": {
                "name": "IGNORANCE",
                "items": []
            },
            "3": {
                "name": "MALE",
                "items": []
            },
            "4": {
                "name": "MELT",
                "items": []
            },
            "5": {
                "name": "BUSY",
                "items": []
            }
        },
        {
            "1": {
                "name": "DRY",
                "items": []
            },
            "2": {
                "name": "WIDTH",
                "items": []
            },
            "3": {
                "name": "OFTEN",
                "items": []
            },
            "4": {
                "name": "HAPPY",
                "items": []
            },
            "5": {
                "name": "FORBID",
                "items": []
            }
        },
        {
            "1": {
                "name": "COMMON",
                "items": []
            },
            "2": {
                "name": "RUDE",
                "items": []
            },
            "3": {
                "name": "SELL",
                "items": []
            },
            "4": {
                "name": "DULL",
                "items": []
            },
            "5": {
                "name": "TEACHER",
                "items": []
            }
        },
        {
            "1": {
                "name": "VALUABLE",
                "items": []
            },
            "2": {
                "name": "SMOOTH",
                "items": []
            },
            "3": {
                "name": "WILD",
                "items": []
            },
            "4": {
                "name": "PUNISH",
                "items": []
            },
            "5": {
                "name": "SLOW",
                "items": []
            }
        },
        {
            "1": {
                "name": "COVERED",
                "items": []
            },
            "2": {
                "name": "COMFORT",
                "items": []
            },
            "3": {
                "name": "AWAKE",
                "items": []
            },
            "4": {
                "name": "REFUSE",
                "items": []
            },
            "5": {
                "name": "MODERN",
                "items": []
            }
        },
        {
            "1": {
                "name": "COWARD",
                "items": []
            },
            "2": {
                "name": "GOOD",
                "items": []
            },
            "3": {
                "name": "DEPART",
                "items": []
            },
            "4": {
                "name": "KIND",
                "items": []
            },
            "5": {
                "name": "WET",
                "items": []
            },
        },
        {
            "1": {
                "name": "HOT",
                "items": []
            },
            "2": {
                "name": "TOGETHER",
                "items": []
            },
            "3": {
                "name": "SMALL",
                "items": []
            },
            "4": {
                "name": "WISE",
                "items": []
            },
            "5": {
                "name": "DISBELIEF",
                "items": []
            },
        },
        {
            "1": {
                "name": "LEAD",
                "items": []
            },
            "2": {
                "name": "MANY",
                "items": []
            },
            "3": {
                "name": "STALE",
                "items": []
            },
            "4": {
                "name": "DISAGREE",
                "items": []
            },
            "5": {
                "name": "NATURAL",
                "items": []
            },
        }
    ]
]
export { anthard }