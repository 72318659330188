import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import Navigation from '../Helper/Navigation';
import './Mobile.scss'
function Drawer({ drawer, action}) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (e, value) => {
        e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };
    return (
        <>
            <>
                    <div
                        onClick={(e) => action(e)}
                        className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
                    ></div>
                    <div className="offcanvas_menu">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className={`offcanvas_menu_wrapper ${
                                            drawer ? 'active' : ''
                                        }`}
                                    >
                                        <div className="canvas_close">
                                            <a href="#" onClick={(e) => action(e)}>
                                                <i className="fa fa-times"></i>
                                            </a>
                                        </div>
                                        <div className="offcanvas-brand text-center mb-40">
                                            <a href="/">
                                                <img src={logo} alt="" />
                                            </a>
                                        </div>
                                        <div id="menu" className="text-left sideMenu">
                                            {/* <ul className="offcanvas_main_menu">
                                                <li
                                                    onClick={(e) => handler(e, 'service')}
                                                    id="service"
                                                    className="menu-item-has-children active"
                                                >
                                                    <a href="/service">hello</a>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'contact')}
                                                    id="contact"
                                                    className="menu-item-has-children active"
                                                >
                                                    <Link to="/contact">Contact</Link>
                                                </li>
                                            </ul> */}
                                            <Navigation/>
                                        </div>
                                        <div className="offcanvas-social">
                                            <ul className="text-center">
                                                <li>
                                                    <a href="https://www.facebook.com/QWACK20">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                
                                                <li>
                                                    <a href="https://www.instagram.com/qwackeducation/">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                        <div className="footer-widget-info">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        <i className="fal fa-envelope"></i>
                                                        support@qwackeducation.com
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        </>
    );
}

export default Drawer;
