import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
export default function Auth({setMagicBar, setMagicNumber}) {
    // const navigate = useNavigate();
    setMagicBar(false);
setMagicNumber(4)
    useEffect(() => {
        // get user from local storage
        const user = JSON.parse(localStorage.getItem('comp4User'));
        // redirect to home if already logged in
        if (user) {
            alert("You are already registered");
            window.location.href = "/competition/waiting";
        }
    }, []);
    const [OTPdisalbed, setOTPdisalbed] = useState(false)
    const [form, setForm] = useState({})
    const onchange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const [isChecked, setIsChecked] = useState(true)

    const onSubmit = () => {
        if (!form.phone) {
            alert("Please fill the form");
            return;
        }
        if(!form.phone.length === 10) {
            alert("Enter a valid phone number")
            return;
        }
        if (!form.standard) {
            alert("Please enter Standard");
            return;
        }
        
        axios.post(`${process.env.REACT_APP_SERVER}/api/comp/participate`, form)
        .then(res => {
            console.log(res);
            if (res.status === 200) {
                // store user data in local storage
                localStorage.setItem("comp4User", JSON.stringify(res.data.user));
                // navigate("/app/dashboard");
                window.location.href = "/competition/waiting";
                alert("Registered successfully")
            } else {
                alert("Registration failed")
            }
        })
        .catch(err => {
            try {
                if(err.response.data.message) {
                    alert(err.response.data.message)
                } else {
                    alert("something went wrong, Please try again.")
                }
            } catch (error) {
                alert("something went wrong, Please try again.")
            }
        })
    }
    return (
        <>
            <div className="signup">
                <div className="signup-container">
                    <div className="signup-header">
                        <h1>Participate</h1>
                        <p>Enter in the the QWACK Grammar Competition</p>
                    </div>
                    <div className="signup-form">
                    <div className="phone">
                            <label htmlFor="Your Phone"><FontAwesomeIcon icon={faWhatsapp} />+91</label>
                            <input type="text" name="phone" id="phone1" placeholder='Enter your WhatsApp Number' onChange={(e) => onchange(e)} />
                        </div>
                        <label htmlFor="standard">Standard:</label>
                        <select name="standard" id="standard" value={form.standard} onChange={(e) => onchange(e)}>
                            <option value="null">Select a Grade</option>
                            <option value="a-2nd">2</option>
                            <option value="a-3rd">3</option>
                            <option value="a-4th">4</option>
                            <option value="a-5th">5</option>
                        </select>
                        <div style={
                            {
                                opacity: 0,
                            }
                        }>
                            {/* <input type="checkbox" name="Privacy" id="privacy" checked={isChecked} onClick={() => { setIsChecked(!isChecked) }} readOnly /> */}
                            <label htmlFor="Privacy" >I agree to the Privacy Policy and Terms of Use</label>
                        </div>
                        <button  className="btn-primary" onClick={() => onSubmit()}>Participate</button>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
